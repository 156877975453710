html {
    scroll-behavior: smooth;
}
body,
html {
    font-family: 'Roboto', sans-serif;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
}
body{
    background-image: url(../img/hereisfoodBg.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center center;

}
/* section {
    overflow: hidden;
} */
header.inner-header {
    background-image: url(../img/header-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
    padding-bottom: 0px;
    box-shadow: 0px 0px 9px #ccc;
    overflow: hidden;
    background: #fff;
}
input:-webkit-autofill{
    -webkit-text-fill-color: #686868;
    -webkit-box-shadow: 0 0 0px 1000px #686868 inset;
    transition: background-color 5000s ease-in-out 0s;
}

/*******Home page start**********/
header .logo a.nav-link {
    display: inline-block;
    /* background: #fff; */
    padding: 5px 15px 20px 102px;
}
header.inner-header a.nav-link {
    padding-left: 15px;
}
header {
    position: absolute;
    width: 100%;
    padding: 0px 0px 10px;
    z-index: 1000;
    overflow: hidden;
    top: 0px;
}

.right-header {
    text-align: right;
    padding: 1px 0px;
}

.user-info-header button {
    background: transparent !important;
    color: #fff !important;
    border: 0px !important;
    box-shadow: none !important;
    padding: 0px 0px;
    height: 27px;
}

.user-info-header button span {
    position: relative;
    top: 0px;
    left: 0px;
}

.user-info-header .dropdown-toggle::after {
    display: none;
}

.user-info-header button span.down-icon {
    position: relative;
    left: 4px;
    top: 0px;
    font-size: 14px;
}

span.location {
    display: inline-block;
    margin-left: 12px;
}

.main-menu .bg-light {
    background-color: transparent !important;
    float: right;
    padding: 10px 53px 10px 0px;
}

.main-menu {
    display: inline-block;
    width: auto;
    position: relative;
    background: #ff8213;
    padding-left: 10px;
    margin-top: 16px;
}

.location .dropdown-menu {
    padding: 0px;
}

.location .dropdown-menu a.dropdown-item {
    padding: 5px 15px;
    background: #1d0e0c;
    color: #fff;
    border-bottom: 1px solid #e2e2e2;
    position: relative;
    overflow: hidden;
}

.location .dropdown-menu a.dropdown-item:hover {
    background: #ff8213;
    border-color: #ff8213;
}

.location .dropdown-menu a.dropdown-item::before {
    content: "";
    background: #fff;
    position: absolute;
    width: 34px;
    left: -32px;
    top: 0px;
    bottom: 0px;
    transform: rotate(-52deg);
    height: 35px;
}

.product-cart {
    float: right;
    position: absolute;
    right: 0px;
    top: 11px;
    cursor: pointer;
}

.main-menu::after {
    content: "";
    position: absolute;
    right: -500px;
    width: 500px;
    background: #ff8213;
    z-index: 1;
    bottom: 0px;
    top: 0px;
}

.main-menu ul li a {
    padding: 3px 15px !important;
    color: #fff !important;
    position: relative;
    display: block;
}
.user-info-header {
    height: 17px;
    position: relative;
    top: -21px;
}

.main-menu::before {
    content: "";
    position: absolute;
    width: 150px;
    top: 0px;
    bottom: 0px;
    left: -55px;
    background-image: url(../img/menu-before.png);
    background-size: contain;
}

.main-menu ul li a::before {
    content: "|";
    right: 0px;
    position: absolute;
    color: #fff;
}

.main-menu ul li a:hover {
    color: #69504c !important;
}

.main-menu ul li:last-child a::before {
    display: none;
}

.product-cart span {
    position: absolute;
    z-index: 10;
    top: -7px;
    border: 2px solid #fff;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    right: -8px;
    background: #ff8213;

}

/*****twin slider********/
.twin-hero {
    height: 100vh;
    /*background-color: #ff8213;*/
    padding-top: 0 !important;
    /*background-image: url(../img/slider-bg.jpg);*/
    width: 100%;
    overflow: hidden;
    position: relative;
}

.twin-hero .hero-shadow {
    position: absolute;
    z-index: 40;
    width: 100%;
    height: 72px;
}

.twin-hero .hero-container {
    width: 100%;
    height: 100%;
}

html:not([data-device-type="mobile"]) .twin-hero .left-hero {
    position: absolute;
    z-index: 20;
    overflow: hidden;
    position: relative;
    /*-webkit-transition: transform .8s;
	transition: transform .8s;*/
    width: 85%;
    height: 100%;
    top: 0;
    transform: skewX(-10deg);
    -moz-transform: skewX(-10deg);
    -webkit-transform: skewX(-10deg);
    left: -35%;
    box-shadow: 4px 0 0px 3px #FF8213;
}

html:not([data-device-type="mobile"]) .twin-hero .left-hero.left-hero-collapsed {
    transform: skewX(-10deg) translateX(-25%);
    -moz-transform: skewX(-10deg) translateX(-25%);
    -webkit-transform: skewX(-10deg) translateX(-25%);
}

html:not([data-device-type="mobile"]) .twin-hero .left-hero.left-hero-collapsed .left-hero-tilt-collapsed {
    transform: skewX(10deg) translateX(17%);
    -moz-transform: skewX(10deg) translateX(17%);
    -webkit-transform: skewX(10deg) translateX(17%);
}

html:not([data-device-type="mobile"]) .twin-hero .left-hero .left-hero-tilt {
    width: 65%;
    height: 100%;
    margin-left: 41%;
    background: url(../img/slider_left.jpg) no-repeat center center;
    background-size: cover;
    transform: skewX(10deg);
    -moz-transform: skewX(10deg);
    -webkit-transform: skewX(10deg);
    /*-webkit-transition: transform .8s;
    transition: transform .8s;*/
    background-position: left 99%;
}

html:not([data-device-type="mobile"]) .twin-hero .left-hero .left-hero-tilt::before {
    content: "";
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    background: rgba(250, 123, 18, 0.25098039215686274);
    z-index: -1;
    width: 100%;
    min-height: 140vh;
    opacity: 0;
}

html:not([data-device-type="mobile"]) .twin-hero .left-hero .left-hero-tilt:hover:before {
    opacity: 1 !important;
    z-index: 10 !important;
}

html:not([data-device-type="mobile"]) .twin-hero .left-hero .left-hero-tilt .side-container {
    float: right;
}

.twin-hero .hero-container .side-container {
    padding-top: 72px;
    width: 80%;
    max-width: 1300px;
    height: 100%;
    position: relative;
    z-index: 1000;
}

.twin-hero .hero-container .initial.active {
    opacity: 1;
}

.twin-hero .hero-container .initial {
    position: absolute;
    width: 400px;
    padding-top: 130px;
    height: auto;
    /*-webkit-transition: opacity .2s;
	transition: opacity .2s;
	opacity: 0;*/
}

.twin-hero .hero-container .active {
    border-bottom: none;
}

html:not([data-device-type="mobile"]) .twin-hero .left-hero .left-hero-tilt .side-container .initial {
    right: 8%;
    text-align: right;
    padding-right: 0;
    top: 220px;
}

html:not([data-device-type="mobile"]) .twin-hero .left-hero .left-hero-tilt .side-container .collapsed {
    right: 42%;
}

.twin-hero .hero-container .collapsed {
    position: absolute;
    width: 300px;
    padding-top: 160px;
    height: auto;
    -webkit-transition: opacity .2s;
    transition: opacity .2s;
    opacity: 0;
}

html:not([data-device-type="mobile"]) .twin-hero .left-hero .left-hero-tilt .side-container .expanded {
    right: 12%;
}

.twin-hero .hero-container .expanded {
    position: absolute;
    width: 85%;
    top: 20%;
    height: auto;
    overflow: auto;
    -webkit-transition: opacity .2s;
    transition: opacity .2s;
    opacity: 0;
}

html:not([data-device-type="mobile"]) .twin-hero .right-hero {
    width: 85%;
    height: 100%;
    position: absolute;
    top: 0;
    right: -25%;
    z-index: 10;
    /*-webkit-transition: transform .8s;
	transition: transform .8s;*/
    transform: translateX(-8%);
    -moz-transform: translateX(-8%);
    -webkit-transform: translateX(-8%);
}

html:not([data-device-type="mobile"]) .twin-hero .right-hero .right-hero-tilt {
    width: 80%;
    height: 100%;
    background: url(../img/right_slider.jpg) no-repeat center center;
    background-size: cover;
    -webkit-transition: transform .8s;
    transition: transform .8s;
}

html:not([data-device-type="mobile"]) .twin-hero .right-hero .right-hero-tilt:before {
    content: "";
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    background: rgba(75, 45, 39, 0.35);
    z-index: -1;
    width: 100%;
    min-height: 140vh;
    opacity: 0;
}

html:not([data-device-type="mobile"]) .twin-hero .right-hero .right-hero-tilt:hover:before {
    z-index: 100 !important;
    opacity: 1 !important;
}

.twin-hero .hero-container .side-container {
    padding-top: 72px;
    width: 80%;
    max-width: 1300px;
    height: 100%;
    position: relative;
    z-index: 1000;
}

html:not([data-device-type="mobile"]) .twin-hero .right-hero .right-hero-tilt .side-container .initial {
    left: 30%;
    padding-left: 0;
    width: 100%;
    text-align: left;
    top: 139px;
}

.twin-hero .hero-container .initial.active {
    opacity: 1;
}

.twin-hero .hero-container .initial {
    position: absolute;
    width: 400px;
    padding-top: 130px;
    height: auto;
    /*-webkit-transition: opacity .2s;
	transition: opacity .2s;
	opacity: 0;*/
}

.twin-hero .hero-container .active {
    border-bottom: none;
}

html:not([data-device-type="mobile"]) .twin-hero .right-hero .right-hero-tilt .side-container .collapsed {
    left: 48%;
}

.twin-hero .hero-container .collapsed {
    position: absolute;
    width: 300px;
    padding-top: 160px;
    height: auto;
    -webkit-transition: opacity .2s;
    transition: opacity .2s;
    opacity: 0;
}

html:not([data-device-type="mobile"]) .twin-hero .right-hero .right-hero-tilt .side-container .expanded {
    left: 11%;
}

.twin-hero .hero-container .expanded {
    position: absolute;
    width: 85%;
    top: 20%;
    height: auto;
    overflow: auto;
    -webkit-transition: opacity .2s;
    transition: opacity .2s;
    opacity: 0;
}

.side-container .initial {
    color: #fff;
    text-align: center;
}

span.round-icon {
    font-size: 45px;
    border: 2px solid #fff;
    width: 65px;
    height: 65px;
    display: inline-block;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}

.collapsed_outer_right,
.collapsed_outer_left {
    opacity: 0;
    z-index: -1;
    text-align: center;
    width: 100%;
}

.collapsed_outer_right {
    padding-left: 29%;
    padding-right: 23%;
    padding-top: 50px;
    color: #fff;
}

.collapsed_outer_left {
    padding-left: 23%;
    padding-right: 29%;
    padding-top: 50px;
    color: #fff;
}

.collapsed_outer_right p,
.collapsed_outer_left p {
    font-size: 18px;
}

.collapsed_outer_right h2,
.collapsed_outer_left h2 {
    font-size: 36px;
}

.praesent_outer {
    margin: 20px 0px;
}

.praesent_img {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.praesent_content {
    text-align: center;
    font-size: 12px;
}

.praesent_content span {
    display: block;
}

.getstarted a {
    background: #fff;
    border: 1px solid #fff;
    padding: 10px 25px;
    border-radius: 3px;
    color: #2b81b9;
    font-size: 15px;
    margin-top: 25px;
    outline: none;
    cursor: pointer;
}

.getstarted a:hover {
    background: transparent;
    color: #fff;
}

/******css by twin slider end*********/
section.search-box {
    background: #efefef;
    padding: 10px 0px;
}

.search-outer {
    display: inline-flex;
    width: calc(100% - 85px);

}

.search-outer .delivery-location {
    width: 230px;
    float: left;
    border: 4px solid #ff8213;
    padding: 20px 20px;
    color: #FF8213;
    background: #fff;
    cursor: pointer;display: flex;
}

.search-outer .delivery-location img {
    margin-right: 6px;
}

.search-outer .delivery-location span {
    position: relative;
    top: 1px;
    left: 9px;
    font-size: 13px;
}

.search-right {
    width: calc(100% - 230px);
    float: left;
    border: 4px solid #ff8213;
    border-left: 0px;
    padding: 0px 0px 0px 14px;
    display: inline-flex;
    background: #fff;
    align-items: center;
    position: relative;
}

.search-right input.form-control {
    border: 0px;
    outline: none;
    box-shadow: none;
    color: #686868;
    padding-left: 6px;
}

.search-right i {
    color: #686868;
    position: relative;
    ''
    top: -1px;
}

.search-right span {
    background: #ff8213;
    display: inline-block;
    position: relative;
    cursor: pointer;
}

.search-right span::before {
    content: "";
    width: 150px;
    position: absolute;
    left: -70px;
    top: 0px;
    bottom: 0px;
    background-image: url(../img/menu-before.png);
    z-index: 1;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
}

.search-right span .btn {
    background: transparent;
    border: 0px;
    font-size: 19px;
    color: #fff;
    padding: 18px;
    cursor: pointer;
    z-index: 100;
    position: relative;
    box-shadow: none;
    outline: none;
}

.search-right::after {
    content: "";
    position: absolute;
    width: 90px;
    right: -80px;
    top: -4px;
    bottom: 0px;
    background-image: url(../img/menu-before.png);
    z-index: 100;
    background-size: contain;
    background-repeat: no-repeat;
    transform: rotate(-180deg);
    height: 72px;
    cursor: pointer;
}

section.trending-categories {
    padding: 20px 50px 10px;
    background-image: url(../img/trending-categories-bg.png);
    background-repeat: no-repeat;
    background-position: right 10px;
    padding-right: 170px;
}
section.trending-categoriestbl {
    padding: 20px 50px 70px;
    background-image: url(../img/tablefood-2.png);
    background-repeat: no-repeat;
    background-position: right 10px;
    padding-right: 170px;
}

.headings-outer h2 {
    color: #472721;
    font-size: 26px;
    padding-left: 15px;
}

.headings-outer p {
    color: #686868;
}

.product_img {
    text-align: center;
    box-shadow: 0px 0px 19.53px 1.47px rgba(178, 178, 178, 0.28);
    background: #fff;
    border: 0px solid rgba(255, 255, 255, 0.62);
    float: left;
    width: 100%;
    line-height: 0px;
    border-radius: 7px;
    overflow: hidden;
}

.product_img img {
    width: 150px !important;
    display: initial !important;
    margin: 0 auto;
    min-height: 150px;
    object-fit: contain;
}

.product_img a {
    display: inline-block;
    width: 100%;
    padding: 30px 0px;
    color: #333333;
    text-decoration: none;
}

.product_outer {
    padding: 25px 15px;
    display: inline-block;
    width: 100%;
}

#popular_product .owl-nav {
    position: absolute;
    top: 40%;
    width: 100%;
}

#popular_product .owl-nav button.owl-next {
    float: right;
}

#popular_product .owl-nav button {
    height: 50px;
    line-height: 0px;
    outline: none;
    background: transparent;
}

#popular_product .owl-nav button span {
    font-size: 76px;
    line-height: 0px;
    color: #fff;
}

#popular_product .owl-nav button.owl-prev {
    position: absolute;
    left: -40px;
}

#popular_product .owl-nav button.owl-next {
    position: relative;
    right: -80px;
}

.product_img a span {
    display: block;
    padding: 20px 0px 0px;
    font-size: 22px;
    margin-top: 15px;
}

.center .product_img a {
    color: #ff8213;
}

.center .product_img {
    border: 0px solid rgba(255, 130, 19, 0.62);
}

.col-md-6.best-food-left {
    padding-right: 0px;
}

.col-md-12.best-food-right {
    padding-left: 10px;
    margin-bottom: 3px;
}

#food-add .owl-nav {
    position: absolute;
    top: 45%;
    width: 100%;
    transform: translateY(-45%);
}

#food-add .owl-nav button.owl-next {
    float: right;
    position: relative;
    right: -150px;
}

#food-add .owl-nav button span {
    font-size: 75px;
    line-height: 0px;
}

#food-add .owl-nav button {
    line-height: 3px;
    outline: none;
    height: 45px;
    color: #a4a4a4;
}

#food-add .owl-nav button.owl-prev {
    position: absolute;
    left: -150px;
}

section.best-food {
    background-image: url(../img/best-food-bg.png);
    background-repeat: no-repeat;
    background-position: left 10px;
    padding:0px 0px 0px;
    background-size: 390px;
}

section.food-collection {
    padding: 20px 0px 0px;
}

.food-outer {
    display: inline-block;
    width: 100%;
    position: relative;
    border: 1px solid #e2e2e2;
    padding: 0px;
    margin-bottom: 20px;
    background: #fff;
    transition: all 0.2s;

}

.food-img {
    display: inline-block;
    width: 100%;
    padding: 10px 10px 0px;
    overflow: hidden;
}

.food-img img {
    border-radius: 5px;
    transition: transform .9s;
    width: 100%;
    height: 150px !important;
}

.food-outer:hover .food-img img{
    transform: scale(1.2);
}

.food-img span.promoted {
    position: absolute;
    top: 80px;
    right: 10px;
    text-transform: uppercase;
    color: #fff;
    font-size: 15px;
    background-image: url(../img/promoted-label.png);
    padding: 3px 14px;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
}

.food-img span.exclusive {
    position: absolute;
    top: 80px;
    right: 10px;
    text-transform: uppercase;
    color: #fff;
    font-size: 15px;
    background-image: url(../img/label-exclusive.png);
    padding: 3px 14px;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
}

.food-content {
    display: inline-block;
    width: 100%;
    padding: 0px 10px 0px;
    border-bottom: 1px solid #e2e2e2;
}

.food-content h5 {
    color: #391e03;
    font-size: 18px;
    margin-bottom: 2px;
    min-height: 45px;
}

.food-content a p {
    font-size: 17px;
    margin: 0px 0px 3px;
    color: #666666;
    height: 28px;
    overflow: hidden;
}

span.star-reating a {
    color: #e2e2e2;
    font-size: 11px;
}

span.star-reating {
    display: inline-block;
    position: relative;
    top: -4px;
}

span.star-reating a.active {
    color: #ff8213;
}

.food-content a {
    text-decoration: none;
}

.date-time {
    margin-top: -3px;
    padding-bottom: 4px;
}

.date-time p {
    margin: 0px;
    font-size: 13px;
    color: #391e03;
    width: 62%;
    display: inline-flex;
}

.date-time p span {
    color: #666666;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.date-time span.open-now {
    font-size: 10px;
    text-align: right;
    display: inline-flex;
    color: green;
    float: right;
    position: relative;
    top: -6px;
}

.location-details {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    padding: 3px 10px;
    font-size: 14px;
    border-bottom: 1px solid #e2e2e2;
    align-items: center;

}

.location-details span.kilomitter {
    min-width: 70px;
}

.location-details span.kilomitter img {
    display: inline-block;
    position: relative;
    top: -2px;
    width: auto;
}

.order-delivery img,
.coupon-order img {
    width: auto !important;
}

.order-delivery {
    padding: 3px 8px;
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid #e2e2e2;
}

.min-order {
    width: 55%;
    float: left;
    position: relative;
    padding-left: 21px;
    font-size: 13px;
    cursor: pointer;
    min-height: 55px;
}

.min-order:last-child {
    width: 45%;
}

.min-order img {
    position: absolute;
    left: 0px;
    top: 2px;
    width: 16px !important;
    transition: transform .9s;
}

.min-order strong {
    display: block;
    color: #FF8213;
}

.coupon-order {
    padding: 5px 10px;
    display: inline-block;
    width: 100%;
}

.coupon-code {
    width: 50%;
    float: left;
    padding-left: 23px;
    position: relative;
    font-size: 11px;
    color: #666666;
    overflow: hidden;

}

.coupon-code img {
    position: absolute;
    left: 0px;
    width: 19px !important;
    transition: transform .9s;
}

.coupon-code:hover img {
    transform: scale(1.1);
}

.coupon-code span {
    font-weight: bold;
    display: block;
    font-size: 17px;
    margin-top: -5px;
}

.order-now {
    float: left;
    width: 100%;
    /* padding-left: 10px; */
}

.order-now a {
    width: 100%;
    background: #FF8213;
    border: 1px solid #FF8213;
    color: #fff !important;
    padding: 5px;
    border-radius: 5px;
    outline: none;
    transition: all 0.5s;
    position: relative;
    left: -2px;
}

section.food-collection {
    background-image: url(../img/food-cl-bg.png);
    background-repeat: no-repeat;
    background-position: left 7%;
    background-size: 320px;
}
section.food-collectiontbl {
    background-image: url(../img/tablefood-1.png);
    background-repeat: no-repeat;
    background-position: left 7%;
    background-size: 320px;
}

.food-collection .owl-nav,
.food-add-slider .owl-nav,
.food-collectiontbl div#food-category .owl-nav{
    position: absolute;
    top: 47%;
    width: 100%;
    transform: translateY(-47%);
}

.food-collection .owl-nav button.owl-next,
.food-add-slider .owl-nav button.owl-next,
.food-collectiontbl div#food-category .owl-nav button.owl-next{
    float: right;
    right: -130px;
    position: relative;
}

.food-collection .owl-nav button.owl-prev,
.food-add-slider .owl-nav button.owl-prev,
.food-collectiontbl div#food-category .owl-nav button.owl-prev {
    position: absolute;
    left: -140px;
}

.food-collection .owl-nav button,
.food-add-slider .owl-nav button,
.food-collectiontbl div#food-category .owl-nav button{
    line-height: 0px !important;
    outline: none;
    color: #a4a4a4 !important;
}

.food-collection .owl-nav button span,
.food-add-slider .owl-nav button span,
.food-collectiontbl div#food-category .owl-nav button span {
    font-size: 65px;
    line-height: 22px;
}

.work-heading h2 {
    font-size: 26px;
    font-weight: 400;
    color: #472721;
}

.work-heading h2 span {
    font-size: 30px;
    font-weight: 600;
}

.food-delivery-outer {
    padding: 20px 0px;
}

section.Works-food-delivery {
    padding: 0px 0px 0px;
    overflow: hidden;
}

.food-add-slider {
    padding: 0px 0px 0px;
}

.owl-nav button:hover {
    color: #FF8213 !important;
}


section.app-download {
    background-image: url(../img/app-download-bg.png);
    background-repeat: no-repeat;
    background-position: bottom;
    padding-top: 0px;
    background-size: 130%;
}

.mobile-img {
    display: inline-block;
    width: 370px;
}

.mobile-img img {
    width: 271%;
    position: relative;
    left: -235px;
}

.download-content {
    width: 45%;
    display: inline-block;
    position: relative;
    z-index: 100;
    top: 168px;
}

.download-content h2 {
    color: #fff;
    font-size: 48px;
    font-weight: 100;
}

.qr-code {
    display: inline-block;
    width: 100%;
    padding: 15px 0px;
}

.qr-code img {
    width: 130px;
}

.qr-code img:first-child {
    margin-right: 70px;
}

.aap-store-google {
    padding: 20px 0px;
    display: inline-block;
    width: 100%;
}

.aap-store-google img:first-child {
    margin-right: 20px;
    width: 140px;
}

.food-man {
    display: inline-block;
    width: 300px;
    position: relative;
    background-image: url(../img/food-man.png);
    background-repeat: no-repeat;
    background-position: bottom;
}
.food-man:hover {
    background-image: url(../img/food-man-eye.png);
    background-repeat: no-repeat;
    background-position: bottom;

}

.app-download .col-md-12 {
    display: flex;
}

footer {
    background-image: url(../img/footer-top.png);
    background-repeat: no-repeat;
    background-color: #fff;
    background-position: center top;
    padding: 50px 0px 0px;
    position: relative;
    overflow: hidden;
    z-index: 110;
}

img.footer-bottom {
    position: absolute;
    right: 0px;
    bottom: 0px;
    z-index: 100;
    width: 350px;
}

.footer-links ul {
    padding: 0px;
    list-style: none;
    margin: 0px;
}

.footer-links ul li a {
    display: block;
    color: #472721;
    line-height: 32px;
    font-size: 15px;
    text-decoration: none;
}

.footer-links ul li a:hover {
    color: #ff8213;
}

.footer-links h3 {
    color: #472721;
    font-size: 18px;
    margin-bottom: 17px;
}

ul.social-list li a {
    display: inline-block;
    margin-right: 7px;
}

ul.social-list li {
    display: inline-block;
}

p.copy-right {
    margin: 10px 0px 0px;
    padding: 11px 0px;
    border-top: 1px solid #e2e2e2;
    font-size: 15px;
}

.food-delivery-outer img {
    max-width: 100%;
}

.food-delivery-outer {
    transition: all 0.9s;
    position: relative;
    top: 0px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

/*.food-delivery-outer:hover {
    top: -18px;
}*/
.navbar-light .navbar-toggler-icon {
    background-image: none;
}

button.navbar-toggler {
    font-size: 21px;
    padding: 9px 7px 0px;
    color: #FFF !important;
    border: 1px solid #fff !important;
    outline: none;
    box-shadow: none;
    cursor: pointer;
}

span.food-delivery-btn button {
    background-image: -moz-linear-gradient(36deg, rgb(255, 130, 19) 57%, rgb(244, 102, 20) 100%);
    background-image: -webkit-linear-gradient(36deg, rgb(255, 130, 19) 57%, rgb(244, 102, 20) 100%);
    background-image: -ms-linear-gradient(36deg, rgb(255, 130, 19) 57%, rgb(244, 102, 20) 100%);
    outline: 7px solid #ffffffb0;
    border: 0px;
    padding: 18px 58px 18px 17px;
    color: #fff;
    font-size: 23px;
    cursor: pointer;
    transform: skew(-30deg);
    display: inline-block;
}

span.food-delivery-btn button img {
    margin-right: 12px;
    width: 35px;
}

span.food-delivery-btn button span {
    transform: skew(30deg);
    position: relative;
    display: inline-block;
}

span.food-delivery-right-btn button {
    background-image: -moz-linear-gradient(36deg, rgb(58, 40, 36) 1%, rgb(75, 45, 39) 39%);
    background-image: -webkit-linear-gradient(36deg, rgb(58, 40, 36) 1%, rgb(75, 45, 39) 39%);
    background-image: -ms-linear-gradient(36deg, rgb(58, 40, 36) 1%, rgb(75, 45, 39) 39%);
    outline: 7px solid #ffffffb0;
    border: 0px;
    padding: 18px 38px;
    color: #fff;
    font-size: 23px;
    cursor: pointer;
    transform: skew(-30deg);
    display: inline-block;
}

span.food-delivery-right-btn button img {
    margin-right: 12px;
    width: 29px;
    position: relative;
    top: -4px;
}

span.food-delivery-right-btn button span {
    transform: skew(30deg);
    position: relative;
    display: inline-block;
}

span.food-delivery-right-btn button:hover {
    background: #3a1c16 !important;
}

span.food-delivery-btn button:hover {
    background: #f86f14 !important;
}

.product_img:hover {
    box-shadow: 0 0 14.53px 2.47px hsla(0, 0%, 73%, 0.58);
    cursor: pointer;
}

.food-outer:hover {
    box-shadow: 0 0 14.53px 2.47px hsla(0, 0%, 73%, 0.58);
}

.order-now a:hover {
    background: #ec6c00;
    border-color: #ec6c00;
    cursor: pointer;
}

.owl-carousel .owl-stage-outer {
    z-index: 10;
}

.best-food-left a img:hover,
.best-food-right a img:hover {
    transform: scale(1.1);
}

.best-food-left a,
.best-food-right a {
    overflow: hidden;
    display: inline-block;
}

.best-food-left a img,
.best-food-right a img {
    transition: transform .9s;
}

/* effect-shine */
.effect-shine:hover {
    -webkit-mask-image: linear-gradient(-75deg, rgba(0, 0, 0, .6) 30%, #000 50%, rgba(0, 0, 0, .6) 70%);
    -webkit-mask-size: 200%;
    animation: shine 2s infinite;
}

@-webkit-keyframes shine {
    from {
        -webkit-mask-position: 150%;
    }

    to {
        -webkit-mask-position: -50%;
    }
}

.mobile-img img {
    transition: transform .9s;
}

.mobile-img img:hover {
    transform: scale(1.05);
}

.food-man img {
    position: relative;
    transition: all 0.9s;
    top: 0px;
}

.food-man img:hover {
    top: 0px;
}

[class^='imghvr-'],
[class*=' imghvr-'] {
    position: relative;
    display: inline-block;
    margin: 0px;
    max-width: 100%;
    background-color: #ffffff;
    color: #fff;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
}

[class^='imghvr-flip-'] img,
[class*=' imghvr-flip-'] img {
    backface-visibility: hidden;
}

[class^='imghvr-'] > img,
[class*=' imghvr-'] > img {
    vertical-align: top;
    max-width: 100%;
}

.imghvr-flip-vert figcaption {
    -webkit-transform: rotateY(90deg);
    -moz-transform: rotateY(90deg);
    -ms-transform: rotateY(90deg);
    -o-transform: rotateY(90deg);
    transform: rotateY(90deg);
    -webkit-transform-origin: 50% 0%;
    -moz-transform-origin: 50% 0%;
    -ms-transform-origin: 50% 0%;
    -o-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
}

.demo h3 {
    font-size: 1.4em;
    line-height: 1.4em;
}

[class^='imghvr-'] h3,
[class^='imghvr-'] p,
[class*=' imghvr-'] h3,
[class*=' imghvr-'] p {
    color: #fff;
    padding: 0px;
    margin: 0;
}

.imghvr-flip-vert:hover figcaption {
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    transform: rotateY(0deg);
}

.imghvr-flip-vert:hover > img {
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -ms-transform: rotateY(-180deg);
    -o-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
}

[class^='imghvr-flip-']:hover > img,
[class*=' imghvr-flip-']:hover > img {
    opacity: 0;
}

[class^='imghvr-flip-'] img,
[class*=' imghvr-flip-'] img {
    backface-visibility: hidden;
}

[class^='imghvr-'],
[class*=' imghvr-'],
[class^='imghvr-']:before,
[class^='imghvr-']:after,
[class*=' imghvr-']:before,
[class*=' imghvr-']:after,
[class^='imghvr-'] *,
[class*=' imghvr-'] *,
[class^='imghvr-'] *:before,
[class^='imghvr-'] *:after,
[class*=' imghvr-'] *:before,
[class*=' imghvr-'] *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.35s ease;
    -moz-transition: all 0.35s ease;
    transition: all 0.35s ease;
}

[class^='imghvr-'] figcaption,
[class*=' imghvr-'] figcaption {
    background-color: #ff8213;
    position: absolute;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.product_img figure {
    width: 100%;
    padding-bottom: 2px;
}

.product_img figcaption a {
    color: #fff;
}

.min-order:first-child:hover img.price-img,
.min-order:last-child:hover img.price-img {
    transform: rotate(360deg);
}

.add_img {
    overflow: hidden;
    padding:18px;
}

.add_img a {
    display: inline-block;
    box-shadow: 0 0 14.53px 2.47px hsla(0, 0%, 73%, 0.58);
    width: 100%;
}

/*.add_img a:hover {
    box-shadow: 0px 0px 21px 7px #ccc;
}*/
/*
.add_img img:hover {
    box-shadow: 0px 0px 15px #e2e2e2;
}
*/
.add_img img {
    transition: all 0.5s;
    border-radius: 5px;
    /* object-fit: cover; */
}

.min-order strong {
    font-size: 14px;
}


.orderPriceing strong {
    color: #000;
    font-size: 12px;
    display: inline-block;
    width: 100%;
    /* white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; */
}
.orderPriceing strong span span {
    color: #adabab;
    display: block;
}

.add_img img:hover {
    transform: scale(1.01);
    box-shadow: 0 0 14.53px 2.47px hsl(0, 0%, 73%);
}

.left-one {
    float: left;
}

.left-two {
    width: 70px;
    float: left;
    padding: 5px;
    position: relative;
    top: 23px;
}

span.contact-line {
    position: relative;
    top: 21px;
    left: 0;
    margin-left: 2px;
}

.left-three {
    width: 270px;
    display: inline-flex;
    align-items: center;
}

.repet-flip h5 {
    font-size: 15px;
    font-weight: normal;
    text-align: center;
    color: #FF8213;
    margin:5px 0px;
}

.repet-flip .product_img a {
    padding: 13px 25px;
    border: 1px solid #FF8213;
    border-radius: 10px;

}

.repet-flip .product_img figure a {
    color: #FF8213;
}

.repet-flip .product_img figure figcaption a {
    color: #fff;
}

.repet-flip .product_img figcaption {
    border-radius: 10px;
}

.left-three .repet-flip {
    width: 180px;
    position: relative;
    top: 5px;
}

.repet-flip .product_img figcaption {
    background-color: #FF8213;
}

.left-four img {
    position: relative;
    top: -55px;
    width: 262px;
}

.repet-flip .product_img a img {
    width: 75px !important;
}

.repet-flip .product_img figcaption a {
    border-color: #FF8213 !important;
}

#food-category .item {
    padding: 15px;
}

.order-now a:hover {
    transform: scale(1.1);
    box-shadow: 0 0 14.53px 2.47px hsla(0, 0%, 73%, 0.58);
}
.owl-theme .owl-nav [class*='owl-']:hover {
    background: transparent;
}

.left-outer figure.imghvr-flip-vert {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    background: transparent;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.left-outer .product_img {
    border-radius: 70%;
    width: 160px;
    height: 160px;
    background: transparent;
    border: 1px solid #FF8213;
}

.left-outer .product_img a {
    padding: .0px;
    border: 0px;
    position: relative;
    top: -9px;
}

.left-outer .repet-flip {
    background: transparent;
    display: inline-block;

}
.left-outer{
    width:160px;
    display:inline-block;
}
.left-outer .product_img figcaption {
    height: 160px;
    width: 160px;
    display: inline-flex;
    align-items: center;
}

.left-outer {
    position: relative;
    top: -20px;
}

.left-outer img.idea-img {
    float: right;
    position: relative;
    left: 40px;
}

section.login {
    position: relative;
    min-height: 100vh;
    border-bottom: 2px solid #ff8213;
    padding-bottom: 30px;
}

section.login::before {
    content: "";
    width: 55%;
    position: absolute;
    left: 0px;
    background-image: url(../img/login-before.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    bottom: 0px;
    min-height: 100vh;
    top: 0px;
}

section.login::after {
    content: "";
    width: 45%;
    position: absolute;
    right: 0px;
    background-image: url(../img/login-after.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    bottom: 0px;
    min-height: 100vh;
    z-index: -1;
    top: 0px;
}

.login-header .user-info-header button {
    color: #fff !important;
}

.login-outer {
    text-align: center;
    padding: 80px 15px 10px;
    width: 80%;
}

.login-outer h3 {
    font-size: 21px;
    margin: 18px 0px;
    font-weight: normal;
}

.login-outer h3 span {
    font-size: 30px;
}

.social-outer span {
    background: #e7e7e7;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 500;
    height: 42px;
    display: inline-flex;
    align-items: center;
    justify-content: left;
    margin: 0px;
    width: 33.33%;
}

.social-outer span:nth-child(2) {
    width: 30%;
    margin: 0px 9px;
}

.social-outer span a {
    color: #000;
    text-decoration: none;
    padding: 7px 18px 7px 9px;
    display: inline-block;
    width: 100%;
    text-align: left;
}

.social-outer span img {
    margin-right: 17px;
}

.social-outer {
    display: inline-flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}

.login-outer h5 {
    margin: 20px 0px;
    font-weight: normal;
    color: #666666;
    font-size: 16px;
}

.login-outer .form-control {
    font-size: 14px;
    height: 48px;
    color: #999999;
    border: 1px solid #d6d6d6;
    box-shadow: none;
}

.input-outer {
    position: relative;
}

.input-outer i {
    position: absolute;
    right: 15px;
    top: 13px;
    font-size: 17px;
    color: #ff9900;
    cursor: pointer;
}

.custom_check {
    display: inline-block;
    position: relative;
    cursor: pointer;
    line-height: 24px;
    position: relative;
    padding-left: 30px;
    margin: 5px 0px;
}

.custom_check a{
    color: #ea6e00;
    text-decoration: underline;
}

.custom_check input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    cursor: pointer;
    margin: 0px 0px;
    z-index: 2;
}

.custom_check .check_indicator {
    height: 22px;
    width: 22px;
    position: absolute;
    top: 0px;
    left: 0px;
    background: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 0px;
}

.custom_check input:checked + .check_indicator:before {
    width: 7px;
    height: 12px;
    position: absolute;
    left: 6px;
    top: 2px;
    border: solid #ff8213;
    border-width: 0px 2px 2px 0px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    content: "";
}

.login-form .form-check {
    padding: 0px;
    text-align: left;
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
}

.login-form .form-check span.custom_check {
    font-size: 14px;
    color: #666666;
}

.login-form .form-check a.forgot-password {
    color: #ff6600;
    font-size: 15px;
    text-decoration: none;
}

.login-outer .form-control:hover,
.login-outer .form-control:focus,
.login-outer .form-control:active {
    box-shadow: 0px 0px 15px #e2e0e0;
}

.login-form button.btn.btn-primary {
    background: #ff8213 !important;
    border: 1px solid #ff8213 !important;
    width: 100%;
    margin: 10px 0px;
    color: #fff;
    font-size: 18px;
    box-shadow: none !important;
}

.login-form button.btn.btn-primary:hover {
    background: #ec6c00 !important;
    border-color: #ec6c00 !important;
    box-shadow: 0px 0px 9.53px 1.47px rgba(178, 178, 178, 0.43) !important;
}

.login-form p.register {
    color: #666666;
    margin: 10px 0px;
    font-size: 16px;
}

.login-form p.register a , p.register a {
    color: #ff6600;
    text-decoration: underline;
}

.login-form p.register a:hover, p.register a:hover {
    color: #ea6e00;
}

.login-form p {
    font-size: 14px;
}

.login-form p a {
    color: #482823;
}

.login-form p span {
    display: block;
}

h2.horizntal {
    display: inline-block;
    transform: rotate(-90deg);
    position: absolute;
    top: 50%;
    right: -70px;
    font-size: 44px;
    color: #2d2d2d;
    font-weight: normal;
}

h2.horizntal span {
    color: #fd820f;
}

.login-right {
    width: 100%;
    text-align: center;
    padding-top: 50px;
}

.login-right img.img-fluid {
    position: relative;
    left: 120px;
    width: 50%;
}

.login-right span {
    display: inline-block;
    width: 100%;
    position: relative;
}

.login-right span::after {
    content: "";
    position: absolute;
    left: 130px;
    width: 5000px;
    background: #642a2d;
    bottom: -14px;
    z-index: 1000;
    height: 15px;
    transform: skew(-30deg);
}

.user-info-header button span i.fa-map-marker-alt {
    font-size: 17px;
    position: relative;
    top: -21px;
}

header.inner-header a.nav-link {
    background: transparent;
    padding-bottom: 10px;
}
.logo>a {
    color: #ff8213;
}
.inner-header .right-header {
    padding-bottom: 0px;
    padding-top: 0px;
    position: relative;
    top: 7px;
}

header.inner-header + section {
    margin-top: 125px;
}

section.breadcrumb-outer {
    border-bottom: 1px solid #e9ecef;
}

.breadcrumb-outer nav {
    display: inline-block;
    width: 100%;
}

.breadcrumb-outer nav ol.breadcrumb {
    margin: 0px;
    background: transparent;
    border-radius: 0px;
    padding: 16px 0px;
}

.breadcrumb-outer nav ol.breadcrumb li a {
    color: #ff8213;
    text-decoration: none;
}

.breadcrumb-outer .breadcrumb-item + .breadcrumb-item::before {
    content: "\f054";
    font-family: FontAwesome;
    color: transparent;
    background-image: url(../img/arrow-right.png);
    background-repeat: no-repeat;
    background-position: 4px;
    padding-right: 1.5rem;
}

.breadcrumb-outer .breadcrumb-item.active {
    color: #999999;
}

.breadcrumb-outer .breadcrumb-item + .breadcrumb-item {
    padding-left: 1.5rem;
}

section.Order-Placed {
    padding: 20px 0px;
    /* background-image: url(../img/best-food-bg.png);
    background-repeat: no-repeat;
    background-position: left 12%;
    background-size: 310px; */
}

.order-heading {
    display: inline-block;
    width: 100%;
}

.order-heading h2 {
    display: inline-block;
    color: #0b0603;
}

.order-heading button, .table_res button, .breadcrumb-outer button {
    float: right;
    background: #ff8213;
    border: 1px solid #ff8213;
    padding: 5px 20px;
    border-radius: 5px;
    box-shadow: none;
    cursor: pointer;
    color: #fff;
    font-size: 17px;
    line-height: 36px;
    outline: none;
    transition: all 0.5s;
}

.order-heading button:hover{
    transform: scale(1.2);
    box-shadow: 0px 0px 9.53px 1.47px rgba(178, 178, 178, 0.43);
    background: #ec6c00;
    border-color: #ec6c00;
}
.table_res button:hover, .Delivery_location button:hover, .breadcrumb-outer button:hover{
    transform: scale(1.05);
    box-shadow: 0px 0px 9.53px 1.47px rgba(178, 178, 178, 0.43);
    background: #ec6c00;
    border-color: #ec6c00;
}
.breadcrumb-outer button {
    margin: 5px 0px;
}
.order-heading button img, .breadcrumb-outer button img, .table_res button img {
    width: 30px;
    position: relative;
    top: -4px;
    left: -7px;
}

.thanku-msg {
    margin: 20px 0px;
    display: inline-block;
    width: 100%;
    background: #fffff2;
    border: 1px solid #dfdfdf;
    padding: 15px;
}

.thanku-msg span.check-img {
    width: 60px;
    float: left;
    text-align: center;
}

.thanku-msg span.thanku-content {
    width: calc(100% - 60px);
    float: left;
    padding-left: 15px;
}

.thanku-msg span.thanku-content h3 {
    color: #FF8213;
    font-size: 22px;
    margin-bottom: 5px;
}

.thanku-msg span.thanku-content p {
    margin-bottom: 5px;
    font-size: 14px;
}

.Order-Summary {
    display: inline-block;
    width: 100%;
    padding: 15px;
    border: 1px solid #dfdfdf;
}

.Order-Summary span.Summary-titel {
    color: #ff8213;
    display: block;
    margin-bottom: 10px;
}

.Order-Summary h3 {
    font-size: 22px;
    color: #333333;
    margin: 0px;
}

.Order-Summary h3 span.point-price {
    float: right;
}

span.Order-confirm-lbl {
    border: 1px solid #07aa26;
    color: #07aa26;
    margin: 8px 0px;
    display: inline-block;
    padding: 5px 25px;
    border-radius: 55px;
    font-size: 14px;
    cursor: pointer;
}

span.Order-confirm-lbl:hover {
    background: #07aa26;
    color: #fff;
}

.date-outer {
    color: #7d7d7d;
    font-size: 14px;
}

.order-name {
    color: #7d7d7d;
    font-size: 14px;
    margin: 5px 0px;
}

.order-name span.timeing {
    float: right;
    display: inline-block;
}

.order-name span.timeing img.price-img {
    position: relative;
    left: -5px;
}

.order-name span.timeing:hover img.price-img {
    transform: rotate(360deg);
    transition: transform .9s;
}

.Restaurant-product {
    padding: 15px 0px;
}

.Restaurant-product h3 {
    font-size: 22px;
    color: #FF8213;
    font-weight: normal;
}

.live-chat {
    text-align: center;
    padding: 20px 0px;
}

.live-chat h4 span {
    display: block;
}

.live-chat h4 {
    font-size: 32px;
    color: #2d2828;
}

.live-chat h4 span a {
    color: #FF8213;
    text-decoration: none;
}

.inner-footer {
    background-image: none;
    border-top: 2px solid #FF8213;
}

.Order-Placed .container {
    background: #fff;
}

.Checkout-item {
    box-shadow: 0px 0px 5px 0px rgba(198, 198, 198, 0.64);
    padding: 15px 5px;
    display: inline-block;
    width: 100%;
}

.Checkout-item .item-img {
    width: 110px;
    text-align: center;
    padding: 10px;
    border: 1px solid #ededed;
    float: left;
    margin-left: 7px;
}

.Checkout-item .item-img img {
    max-width: 100%;
}

.Checkout-item-content {
    width: calc(100% - 117px);
    float: left;
    padding-left: 15px;
}

.Checkout-item-content h4 {
    color: #472823;
    font-size: 27px;
    margin-bottom: 0px;
}

.location-reating span i {
    font-size: 15px;
}

.location-reating span {
    color: #898989;
    display: inline-block;
    padding-right: 20px;
    word-break: break-all;
    font-size: 15px;
    top:-2px;
}

.location-reating {
    padding: 2px 0px;
}

span.star-reating i {
    font-size: 15px;
    position: relative;
    top: 3px;
    color: #ccc;
}

span.star-reating i.active {
    color: #ff8213;
}

.location-reating span span {
    padding: 0px;
    font-size: 15px;
    position: relative;
    top: 4px;
    left: 8px;
}

span.total-review a {
    font-weight: 600;
    color: #ff8213;
    position: relative;
    top: 1px;
    font-size: 15px;
    text-decoration: none;
}

.Checkout-item-content p {
    font-size: 14px;
    color: #898989;
    margin: 0px;
}

.Checkout-item-content p span {
    display: block;
}

.Delivery-address {
    margin: 20px 0px;
    display: inline-block;
    width: 100%;
    background: #fffff2;
    border: 1px solid #dfdfdf;
    padding: 15px;
}

.Delivery-address p {
    color: #ff8213;
    font-size: 14px;
    margin: 0px;
    line-height: 27px;
    width: calc(100% - 70px);
    float: left;
}

.Delivery-address p span {
    display: block;
    color: #363636;
}

span.Change {
    float: right;
}

span.Change a {
    color: #ff8213;
    text-decoration: none;
    font-size: 14px;
}

.order-form-outer {
    padding: 15px 0px 0px;
    border: 1px solid #dfdfdf;
}

.order-form-outer h5 {
    font-size: 16px;
    color: #ff8213;
    padding-left: 15px;
}

.order-form-outer h4 {
    padding-left: 15px;
    font-size: 22px;
    color: #333333;
}

ul.order-list {
    padding: 0px;
    list-style: none;
    margin: 0px;
    border-bottom: 1px solid #dfdfdf;
}

ul.order-list li {
    border-bottom: 1px solid #dfdfdf;
    padding: 5px 15px;
    display: inline-block;
    width: 100%;
}

ul.order-list li .order-left {
    width: 80%;
    float: left;
    position: relative;
    padding-left: 30px;
}

ul.order-list li .order-left span.green-point {
    position: absolute;
    left: 0px;
    width: 20px;
    height: 20px;
    border: 1px solid #33cc66;
    top: 3px;
}

ul.order-list li .order-left span.green-point::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #33cc66;
    left: 4px;
    top: 4px;
}

span.product-name {
    margin-bottom: 0px;
    display: block;
}

.price-outer {
    font-size: 20px;
    margin-bottom: 5px;
}

.price-outer span {
    display: inline-block;
    margin-right: 20px;
    text-decoration: line-through;
}

ul.order-list li .order-left p {
    color: #7d7d7d;
    font-size: 15px;
    margin-bottom: 4px;
}
p.custitemview span.custitile {
    color: #000;
}
.order-left p.custitemview span+span{
    margin-left:5px;
}
span.add-remove-outer {
    display: flex;
    width: 100px;
    float: right;
}

span.add-remove-outer button {
    background: transparent;
    border: 1px solid #FF8213;
    text-align: center;
    color: #FF8213;
    cursor: pointer;
    box-shadow: none;
    outline: none;
}

span.add-remove-outer button.minus {
    border-radius: 5px 0px 0px 5px;
}

/*span.add-remove-outer button:hover {
    background: #FF8213;
    color: #fff;
    cursor: pointer;
}*/
span.add-remove-outer button.plus {
    border-radius: 0px 5px 5px 0px;
}

span.add-remove-outer input.form-control {
    border: 2px solid #FF8213;
    text-align: center;
    border-left: 0px;
    border-right: 0px;
    border-radius: 0px;
    background: #FF8213;
    color: #fff;
    font-size: 20px;
    padding: 1px 0px;
    width: 24px;
    box-shadow: none !important;
}

span.total-price {
    display: block;
    float: right;
    width: 100%;
    text-align: right;
    padding: 8px 0px;
    color: #10100f;
    font-size: 19px;
}

.order-right {
    width: 20%;
    float: left;
}

.custom_radio {
    display: inline-block;
    position: relative;
    cursor: pointer;
    line-height: 19px;
    position: relative;
    padding-left: 26px;
    margin: 5px 0px;
    font-size: 14px;
}

.custom_radio input {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    margin: 0px 0px;
    z-index: 2;
}

.custom_radio .radio_indicator {
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background: #ffffff;
    border: 1px solid #FF8213;
    position: absolute;
    top: 0px;
    left: 0px;
}

.custom_radio input:checked + .radio_indicator:before {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #FF8213;
    position: absolute;
    left: 10px;
    top: 10px;
    margin: -6px 0px 0px -6px;
    content: "";
}

.Delivery-outer-green {
    padding: 0px 0px 0px 15px;
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid #dfdfdf;
}

.Delivery-outer-green h5 {
    padding-left: 0px;
    margin-bottom: 12px;
}

ul.order-list li:last-child {
    border: 0px;
}

.Delivery-left {
    width: 60%;
    float: left;
    border-right: 1px solid #dfdfdf;
    padding: 15px 15px 15px 0px;
}

.Delivery-time {
    padding: 15px;
    border: 1px solid #dfdfdf;
    margin: 10px 0px 0px;
}

.future-order {
    padding: 15px;
    border: 1px solid #dfdfdf;
    border-top: 0px;
}

span.date {
    display: inline-block;
    width: calc(100% - 150px);
}

span.select-velue {
    display: inline-block;
    width: 68px;
}

select.custom-select {
    background-image: url(../img/whiteArroww.png);
    background-size: 12px;
    background-position: 86% 50%;
}

.future-order .form-control,
.future-order select.custom-select {
    box-shadow: none;
    border: 1px solid #dfdfdf;
}

.green-right {
    padding: 15px 15px 0px 15px;
    display: inline-block;
    width: 40%;
}

.green-right h4 {
    font-size: 17px;
    color: #4ab825;
    padding-left: 0px;
}

.green-right span.custom_check {
    font-size: 14px;
    color: #7d7d7d;
}

.Delivery-outer-green:last-child {
    border-bottom: 0px;
}

.apply-code {
    display: flex;
    padding-bottom: 40px;
    align-items: center;
    justify-content: space-between;
}
.apply-code label {
    margin-bottom: 0px;
}

.apply-code input.form-control {
    border-radius: 0px;
    resize: none;
    height: 55px;
    box-shadow: none;
    border: 1px solid #dfdfdf;
    width: 60%;
}

.apply-code button {
    background: transparent;
    border: 0px;
    padding: 0px;
    color: #FF8213;
    box-shadow: none;
    outline: none;
    cursor: pointer;
}

.green-right textarea.form-control {
    border-radius: 0px;
    resize: none;
    height: 55px;
    box-shadow: none;
    border: 1px solid #dfdfdf;
}

.payment-method {
    border: 1px solid #dfdfdf;
    border-top: 0px;
    padding: 15px 0px;
    display: inline-block;
    width: 100%;
}

.payment-method h5 {
    padding: 0px 15px;
    color: #ff8213;
    font-size: 17px;
}

.payment-type-outer h6 {
    background: #f8f8f8;
    padding: 13px 15px;
    font-size: 15px;
    font-weight: normal;
    margin: 0px;
}

span.payment-item {
    display: inline-block;
    float: right;
}

.card-left {
    padding: 9px 15px;
    border-bottom: 1px solid #dfdfdf;
}

span.payment-item a img {
    width: 40px;
    position: relative;
    top: -4px;
}

.special-line .form-control {
    font-size: 14px;
    outline: none;
    box-shadow: none;
    min-height: 100px;
    margin: 15px;
    width: calc(100% - 30px);
}

.special-line {
    padding: 15px 0px;
}

.sidebar-right {
    border: 1px solid #dfdfdf;
}

.sidebar-right h4 {
    padding: 11px 15px;
    background: #ffedde;
    font-size: 17px;
    margin: 0px;
}

.ammount {
    padding: 15px 15px 5px;
    border-bottom: 1px solid #dfdfdf;
    display: inline-block;
    width: 100%;
    margin-bottom: 10px;
}

.ammount p {
    margin-bottom: 7px;
    font-size: 15px;
}

.ammount p span {
    float: right;
    font-weight: 600;
}

.total-ammount {
    padding: 0px 15px;
    font-weight: 600;
}

.total-ammount p span {
    float: right;
    font-size: 15px;
}

.total-ammount button, .total-ammount a {
    background: #FF8213;
    border: 1px solid #FF8213;
    width: 100%;
    padding: 10px 0px;
    margin: -4px 0px 15px;
    color: #fff;
    font-weight: 600;
    font-size: 17px;
    border-radius: 3px;
    cursor: pointer;
    transition: transform .9s;
    transition: all 0.5s;
    outline: none;
    box-shadow: none;
}

.total-ammount button:hover, .total-ammount a:hover {
    background: transparent;
    color: #FF8213;
}
.total-ammount a:hover {
    color: #FF8213 !important;
    text-decoration: none;
}
.total-ammount a{
    width:100%;
    display: inline-block;
    text-align: center;
}

.order-details {
    border-top: 1px solid #e2e2e2;
    display: inline-block;
    width: 100%;
    margin-top: 12px;
}

.order-details .order-delivery {
    padding: 15px 0px 0px;
    border: 0px;
    float: left;
    width: 35%;
}

.order-details .order-delivery .min-order {
    position: relative;
}

.order-details .order-delivery .min-order::after {
    top: 0px;
    content: "";
    height: 100%;
    position: absolute;
    right: 15px;
    width: 2px;
    border-left: 1px dashed #a9a9a9;
    bottom: 0px;
}

.copun_code {
    width: 15%;
    float: left;
    padding-top: 18px;
}

.copun_code .coupon-code {
    width: 100%;
    padding-left: 28px;
}

.copun_code .coupon-code span {
    color: #ff8213;
}

.switch {
    width: 70px;
    height: 35px;
    display: inline-block;
    border-radius: 35px;
    overflow: hidden;
    position: relative;
    margin: 5px 0px;
}

.switch input {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
    padding: 0px 0px;
    margin: 0px 0px;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #c6c6c6;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    width: 34px;
    height: 35px;
    border-radius: 50%;
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #d8d8d8;
    -webkit-transition: .5s;
    transition: .5s;
    text-indent: 30px;
    font-size: 14px;
    color: #2196F3;
    box-shadow: 1px 1px 2px #989696;
    top: 0;
}

.switch input:checked + .slider {
    background-color: #ff8213;
}

.switch input:checked + .slider:before {
    left: calc(100% - 34px);
    content: "";
    color: #ffffff;
    text-indent: -30px;
    background: #fff;
}

.food-veg {
    width: 20%;
    float: left;
    padding: 15px 0px 0px;
    text-align: right;
    padding-left: 15px;
}

.food-veg span.veg-span {
    display: inline-block;
    float: left;
    position: relative;
    top: 6px;
    color: #666666;
}

.table_res {
    width: 30%;
    float: left;
    padding: 15px 0px 0px;
}

.restaurant_menu_detail .Checkout-item {
    padding-bottom: 3px;
}

.category-left {
    width: 100%;
    float: left;
    padding: 0px 10px 0px;
    display: flex;
}
span.filterimg img {
    width: 38px;
}
span.filterimg {
    width: 50px;
    display: inline-block;
    cursor: pointer;
}

.product_name {
    width: calc(100% - 50px);
}
.catrgory_search {
    float: left;
    width: 100%;
}
.product_name .owl-nav {
    width: 100%;
    margin:0px;
}
.product_name .owl-nav button.owl-next {
    float: right;
}
.product_name .owl-nav button span {
    font-size: 38px;
    color: #ff8213;
    line-height: 5px;
}
.product_name .owl-stage {
    padding: 0px 0px;
}
.product_name .item a {
    color: #333333;
    padding: 0px 0px 7px;
    display: inline-block;
    width: 100%;
    text-align: center;
    text-decoration: none;
    border-bottom: 3px solid transparent;
}
.product_name .item a:hover, .product_name .item a.active {
    border-bottom-color: #ff8213;
}
.product_name {
    position: relative;
    padding: 0px 10px;
}
.product_name .owl-nav button.owl-prev {
    position: absolute;
    top: 3px;
    z-index: 10000;
    outline: none;
    box-shadow: none;
    background: #fff !important;
    line-height: 13px !important;
    left: 0px;
}
.product_name .owl-nav button.owl-next {
    position: absolute;
    right: 0px;
    top: 3px;
    z-index: 10000;
    outline: none;
    box-shadow: none;
    background: #fff !important;
    line-height: 13px !important;
}
.product_name{
    position: relative;
}
.category-outer {
    display: inline-block;
    width: 100%;
    border: 1px solid #ccc;
    padding: 15px 0px 0px;
}
.product_name .item a.active {
    font-weight: 500;
}
.secrch-right {
    width: 91%;
    float: left;
    padding: 0px;
    border: 3px solid #ff8213;
    margin: 0px 1%;
    border-right: 0px;
    position: relative;
    top: -7px;
}
.secrch-right input.form-control {
    border: 0px;
    width: 65%;
    float: left;
    font-size: 18px;
}
.secrch-right span {
    width: 50px;
    background: #ff8213;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    float: right;
    cursor: pointer;
}
.secrch-right span input[type="submit"] {
    position: absolute;
    opacity: 0;
}
.secrch-right span i {
    font-size: 20px;
    color: #fff;
}
.secrch-right span::before {
    content: "";
    background-image: url(../img/menu-before.png);
    position: absolute;
    left: -60px;
    top: 0px;
    bottom: 0px;
    height: 100%;
    width: 60px;
    cursor: pointer;
}
.secrch-right::after {
    content: "";
    background-image: url(../img/menu-before.png);
    position: absolute;
    width: 50px;
    height: 53px;
    right: -50px;
    transform: rotate(-180deg);
    top: -3px;
    cursor: pointer;
}
.secrch-right input.form-control:focus{
    box-shadow: none;
}
.Recommended_product{
    display: inline-block;
    width: 100%;
}
.Recommended_inner{
    display: inline-block;
    width: 100%;
    background: #fff6ef;
}
.Recommended_product_outer {
    display: inline-block;
    width: 100%;
}
.Recommended_product_img {
    display: inline-block;
    width: 100%;
    position: relative;
}
.Recommended_product_img img {
    width: 100%;
    border-radius: 5px;
    height: 160px;
    object-fit: contain;
}
.product_content {
    padding: 10px 0px;
}
.product_content h5 {
    margin: 0px;
    font-size: 16px;
    color: #0d0d0d;
}
.product_content p {
    font-size: 15px;
    color: #7d7d7d;
    padding: 4px 0px;
    margin: 0px;
}
.add-product_price {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 10px;
}
.add-product_price span.price-product {
    color: #333;
    font-size: 18px;
}
.add-product_price .add-item {
    background: #fff;
    padding: 0px 0px 0px 5px;
    border-radius: 5px;
    border: 1px solid #ff8213;
    cursor: pointer;
}
.add-product_price .add-item span {
    padding-left: 11px;
    color: #ff8213;
    position: relative;
    top: -4px;
}
.add-product_price .add-item button {
    margin-left: 10px;
    background: #FF8213;
    border: 0px;
    padding: 0px 11px;
    color: #fff;
    font-size: 25px;
}
.Recommended_product_img span {
    position: absolute;
    width: 20px;
    height: 20px;
    background: #fff;
    bottom: 15px;
    right: 15px;
    border: 1px solid #3dce6d;
}
.Recommended_product_img span::after {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    left: 50%;
    background: #3dce6d;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
}
.veg_pizza h5 {
    margin-top: 4px;
    padding: 0px 15px 14px;
    border-bottom: 1px solid #dee2e6;
    margin-bottom: 0px;
}
.veg_pizza h6 {
    background: #fff6ef;
    padding: 10px 15px;
    margin-bottom: 0px;
}
.veg_outer {
    padding: 15px;
    display: inline-block;
    width: 100%;
    float: left;

}
.veg_outer span.product_line {
    width: 20px;
    border: 1px solid #3dce6d;
    display: inline-block;
    height: 20px;
    margin-right: 15px;
    float: left;
    position: relative;
}
.veg_outer span.product_line::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 10px;
    height: 10px;
    background: #3dce6d;
    border-radius: 50%;
    transform: translate(-50%, -50%);
}
.product-right-content {
    width: calc(100% - 150px);
    float: left;
    padding-left: 10px;
}
.product-right-content h4 {
    font-size: 16px;
}
.product-right-content  span.order-price {
    display: block;
    font-size: 18px;
}
.product-right-content p {
    display: block;
    margin: 0px;
    color: #7d7d7d;
    font-size: 15px;
    margin-top: 7px;
    min-height: 67px;
}
.product-right-content p span {
    display: block;
    color: red;
    font-size: 14px;
    cursor: pointer;
}
.veg_outer .order-right {
    width: 100px;
    float: left;
}
span.line-thought {
    display: inline-block;
    margin-right: 10px;
    text-decoration: line-through;
}
.Delivery_location h4, .Food_basket h4 {
    font-size: 18px;
    background: #ffedde;
    padding: 10px 7px;
    width: 100%;
    margin-bottom: 0px;
}
.Delivery_location h4>img, .Food_basket h4>img {
    margin-right: 6px;
}
.Delivery_location h4 span>img {
    float: right;
}
.Delivery_location, .Food_basket {
    display: inline-block;
    width: 100%;
    border: 1px solid #ddc3ac;
    padding: 2px;
}
.location_search {
    padding: 10px 7px;
    position: relative;
}
.location_search input.form-control {
    background-image: url(../img/down-arrow.png);
    background-repeat: no-repeat;
    background-position: 95% 55%;
    cursor: pointer;
    outline: none;
    box-shadow: none;
    border: 1px solid #dee2e6;
    border-radius: 3px 3px 0px 0px;
}
ul.product_listing {
    padding: 0px;
    list-style: none;
    background: #f7f7f7;
    border: 1px solid #ddc3ac;
    margin: 0px;
    border-top: 0px;
    position: absolute;
    width: calc(100% - 14px);
    z-index: 100;
}
ul.product_listing li a {
    display: block;
    color: #333;
    padding: 6px 15px;
    text-decoration: none;
    border-bottom: 1px solid #ddc3ac;
}
ul.product_listing li a:hover {
    color: #fff;
    background: #ff8213;
}
ul.product_listing li:last-child a {
    border-bottom: 0px;
}
.Delivery_location button {
    width: 100%;
    margin-top: 10px;
    background: #ff8213;
    border: 1px solid #ff8213;
    padding: 4px 20px;
    border-radius: 5px;
    box-shadow: none;
    cursor: pointer;
    color: #fff;
    font-size: 17px;
    line-height: 30px;
    outline: none;
    transition: all 0.5s;
    text-transform: uppercase;
}
.Food_basket .veg_outer {
    padding: 15px 2px;
    border-bottom-color: #ddc3ac !important;
}
.Food_basket .veg_outer h4 {
    background: transparent;
    font-size: 14px;
    padding: 0px;
    font-weight: normal;
}
.Food_basket .veg_outer span.product_line{
    margin-right: 9px;
}
.Food_basket .product-right-content {
    width: calc(100% - 35% - 23px);
    float: left;
    padding-right: 12px;
    padding-left: 0px;
}
.Food_basket .veg_outer .order-right {
    width: 28%;
    float: right;
}
.Food_basket .product-right-content p {
    margin: 0px;
    font-size: 12px;
    margin-top: 2px;
}
.Food_basket span.add-remove-outer{
    width: 85px;
}
.Customization_modal .modal-header {
    position: relative;
    text-align: center;
    display: inline-block;
}
.Customization_modal .modal-header button.close, .swal2-close {
    position: absolute;
    right: 6px;
    top: 6px;
    background: #ff1111 !important;
    padding: 0px !important;
    width: 32px !important;
    height: 32px !important;
    opacity: 1 !important;
    border-radius: 50% !important;
    border: 3px solid #fff !important;
    color: #fff !important;
    font-size: 22px !important;
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
    outline: none !important;
    box-shadow: none !important;
}
.swal2-close {
    right: -10px !important;
    top: -10px !important;
}
.Customization_product_content h4 {
    font-size: 26px;
    color: #ff8213;
    margin-bottom: 2px;
}
.Customization_product_content p {
    font-size: 14px;
    display: block;
    float: left;
    color: #4a4948;
    width:100%;
}
.Customization_product_content span.add-remove-outer {
    float: left;
    margin-right: 15px;
}
span.customizations_price {
    font-size: 19px;
    font-weight: bold;
    position: relative;
    top: 5px;
}
p.materials {
    margin: 12px 0px;
}
ul.materials_list {
    display: inline-block;
    width: 100%;
    padding: 0px;
    list-style: none;
    margin: 0px;
}
ul.materials_list li {
    display: inline-block;
    position: relative;
    margin-bottom: 10px;
    margin-right: 5px;
}
ul.materials_list li a {
    color: #333;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    padding: 5px 19px;
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    background: #d4d4d4;
    text-decoration: line-through;

}
ul.materials_list li input {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0px;
    bottom: 0px;
    top: 0px;
    right: 0px;
    z-index: 2;
    opacity: 0;
    cursor: pointer;
}
ul.materials_list li input:checked + a {
    background: #fd8212;
    color: #fff;
    border-color: #fd8212;
    text-decoration: none;
}
ul.materials_list li a:hover {
    background: #FF8213;
    border-color: #FF8213;
    color: #fff;
}
.Beverage select.custom-select.form-control {
    width: 20%;
    background-position: 93%;
    border: 1px solid #ccc;
}
.Material .card-header {
    background: transparent;
    border: 0px;
    border-top: 1px solid #d0d0d0;
    border-radius: 0px;
    padding: 0px;
}
.Material .card {
    border: 0px;
}
.Material .card:last-child {
    border-bottom: 1px solid #d0d0d0;
    border-radius: 0px;
}
.Material .card-header button.btn {
    padding: 10px 15px;
    font-weight: bold;
    color: #FF8213;
    font-size: 20px;
    letter-spacing: 0.8px;
    text-decoration: none;
    width: 100%;
    text-align: left;
}
.switch_product .switch { min-width: 70px; height: 40px; display: inline-block; border-radius: 35px; overflow: hidden; position: relative; margin:5px 5px 5px 0px;}
.switch_product .switch input { width: 100%; height: 100%; opacity: 0; position: absolute; top: 0px; left: 0px; z-index: 2; padding: 0px 0px; margin: 0px 0px;}
.switch_product .slider { position: absolute; cursor: pointer; top: 0; left: 0; right: 0; bottom: 0; background-color: #eeeeee; -webkit-transition: .4s; transition: .4s;}
.switch_product .slider:before { top: 4px; width: 32px; height: 32px; border-radius: 50%; content: ""; position: absolute; left: 4px; bottom: 4px; background-color: #ffffff; -webkit-transition: .5s; transition: .5s; text-indent: 30px; font-size: 14px; color: #2196F3;}
.switch_product .switch input:checked + .slider { background-color: #FF8213;}
.switch_product .switch input:checked + .slider:before { left: calc(100% - 36px); content: ""; color: #ffffff; text-indent: -30px;}
.switch_product {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}
.switch_product .switch+span {
    margin-left: 8px;
    font-size: 16px;
}
.share_add {
    text-align: center;
    padding: 30px 15px;
}
.share_add button.share_pd {
    display: inline-block;
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px -1px 8.82px 0.18px rgba(185, 185, 185, 0.74);
    border: 1px solid #fff;
    font-size: 18px;
    padding: 10px 25px;
    margin: 0px 11px;
    cursor: pointer;
}
.share_add button.share_pd:hover {
    background: #eee;
    border-color: #eee;
}
.share_add button.add_pd {
    display: inline-block;
    border-radius: 5px;
    background-color: rgb(255, 129, 23);
    box-shadow: 0px -1px 8.82px 0.18px rgba(185, 185, 185, 0.74);
    border: 1px solid #FF8213;
    font-size: 18px;
    padding: 10px 25px;
    margin: 0px 11px;
    cursor: pointer;
    color: #fff;
}
.share_add button.add_pd:hover {
    background: #f86f14;
    border-color: #f86f14;
}
.share_add button.add_pd_dis {
    display: inline-block;
    border-radius: 5px;
    background-color: #808080;
    box-shadow: 0px -1px 8.82px 0.18px rgba(185, 185, 185, 0.74);
    border: 1px solid #404040;
    font-size: 18px;
    padding: 10px 25px;
    margin: 0px 11px;
    cursor: pointer;
    color: #fff;
}
.share_add button.add_pd_dis:hover {
    background: #AAAAAA;
    border-color: #808080;
}
.Customization_modal .modal-content {
    background-image: url(../img/transparent_logo.png), url(../img/transparent_logo_bottom.png);
    background-repeat: no-repeat, no-repeat;
    background-position: right top, left bottom;
}
.Material .card-header button.btn i {
    float: right;
    position: relative;
    top: 4px;
    color: #c5c5c5;
    transform: rotate(180deg);
    transition: transform .5s;
}
.Material .card-header button.btn.collapsed i{
    transform: rotate(0deg);
}
.Restaurant_overview .Checkout-item {
    box-shadow: none;
    padding:0px;
}
.Restaurant_overview .Checkout-item .item-img {
    position: relative;
    top: -50px;
    background: #fff;
    border-radius: 3px;
}
.cover-left {
    width: 55%;
    float: left;
}
.cover_right{
    width: 45%;
    float: left;
}
.social_item {
    text-align: right;
    padding: 5px 0px;
}
.social_item a {
    display: inline-block;
    padding: 3px 8px;
    border: 1px solid rgba(255, 130, 19, 0.62);
    border-radius: 4px;
    margin-left: 5px;
    color: #ff8213;
    font-size: 20px;
    position: relative;
}
.social_item a input {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
    cursor: pointer;
}
.social_item a.wishlist {
    padding: 0px;
}
.social_item a.wishlist:hover {
    background: transparent;
    color: #ff8213;
}
.social_item a.wishlist span {
    padding: 3px 8px;
    display: inline-block;
}
.social_item a input:checked + span {
    background-color:#ff8213;
    color: #fff;

}
.social_item a:hover {
    background: #ff8213;
    color: #fff;
}
.Restaurant_overview .Checkout-item-content {
    padding-top: 10px;
}
.Delivery_table .table_res {
    width: 34%;
    float: left;
    padding: 15px 0px 0px;
    margin-left: 4%;
}
.Delivery_table .table_res button {
    float: right;
    background: #ff8213;
    border: 1px solid #ff8213;
    padding: 1px 7px;
    border-radius: 5px;
    box-shadow: none;
    cursor: pointer;
    color: #fff;
    font-size: 15px;
    line-height: 36px;
    outline: none;
    transition: all 0.5s;
}
.table_res_Delivery {
    width: calc(100% - 38%);
    float: right;
    text-align: right;
    padding-top: 15px;
}
.Delivery_table .table_res_Delivery button {
    float: right;
    background: transparent;
    border: 1px solid #ececec;
    padding: 1px 16px;
    border-radius: 5px;
    cursor: pointer;
    color: #ff8213;
    font-size: 15px;
    line-height: 36px;
    outline: none;
    transition: all 0.5s;
    position: relative;
    left: 0;
    box-shadow: -0.788px 0.616px 1px 0px rgba(0, 0, 0, 0.12);
}
.Delivery_table .table_res_Delivery button img {
    width: 26px;
    position: relative;
    left: -5px;
    top: -2px;
}
.Delivery_table .table_res_Delivery button:hover{
    transform: scale(1.05);
}
.overview_menu .list-group {
    display: initial;
    width: 100%;
    flex-direction: column-reverse;
}
.overview_menu .list-group .list-group-item {
    display: initial;
    float: left;
    min-width: 130px;
    width: auto;
    border: 0px;
    text-align: center;
    border-radius: 0px;
    background: transparent;
    color: #333;
    border-bottom: 3px solid transparent;
    padding: 15px 15px 12px;
}
.overview_menu {
    display: inline-block;
    width: 100%;
}
.overview_menu .list-group .list-group-item.active {
    font-weight: 500;
    border-bottom-color: #ff8213;
}
.offer_outer .coupon-code span{
    color: #d7711c;
}
.offer_time .coupon-code {
    padding: 0px;
    width: 100%;
}
.offer_time .coupon-code span {
    font-weight: normal;
    font-size: 16px;
}
.offer_time .coupon-code span.opening_time {
    margin: 2px 0px;
    color: #00a14c;
}
.offer_outer .coupon-code, .offer_outer .min-order{
	width: 100%;
	font-size: 16px;
}
.address small {
    color: #ff8213;
}
.flex {
    margin: 5px 0px 0px;
}
.Restaurants_list .item-img {
    width: 40px;
    float: left;
}
.Restaurants_list .item-img img {
    width: 100%;
    border-radius: 4px;
}
.Restaurants_list .Checkout-item-content {
    width: calc(100% - 40px);
    float: left;
    padding: 0px 0px 0px 10px;
}
.Restaurants_list .Checkout-item-content h4 {
    background: transparent;
    padding: 0px;
}
.Restaurants_list {
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid #ddc3ac;
}
.Restaurants_list:last-child {
    border-bottom: 0px;
}
.domins_search .search-outer {
    width: 90%;
    margin: 0 auto;
}
.Food_basket .total-ammount {
    padding: 0px 5px;
}

.Food_basket .total-ammount a {
    margin: 0px 0px 5px;
}
header.inner-header + .main {
    margin-top: 90px;
    min-height: 65vh;
}
.dropdown {
    display: flex;
}

.dropdown select.custom-select {
    background-color: transparent;
    border: 0px;
    padding: 0px 27px 0px 5px;
    box-shadow: none;
    color:#fff;
    height: 31px;
}
.restaurantImages img {
    height: 310px;
    object-fit: cover;
}
section.Order-Placed.restaurant_menu_detail .item {
    display: inline-block;
    width: calc(100% / 4);
}
.thumbnail {
    padding: 5px;
}
.thumbnail img{
    width: 100% !important;
    height: 250px !important;
    object-fit: cover   ;
}
.react-fancybox .box{
    z-index: 10000 !important;
}
span.location .dropdown i {
    font-size: 18px;
    position: relative;
    top: 2px;
}
.react-tabs__tab-list{
    border-bottom: 1px solid #e9ecef !important;
}
.react-tabs__tab-list li {
    border: 0px;
    border-bottom: 3px solid transparent;
    box-shadow: none !important;
}
.react-tabs__tab-list li.react-tabs__tab--selected {
    border: 0px !important;
    color: #ff8213 !important;
    border-radius: 0px;
}
.location .dropdown span i {
    font-size: 19px;
    top: 4px;
    left: 0px;
    color: #fff;
}
.main-menu ul li a:hover {
    color: #fff !important;
    text-decoration: none !important;
}
/***************12/19/2019******/
.reviewListOuter h3 {
    color: #ff8213;
}
.userReview {
    border: 1px solid #dee2e6;
    padding: 10px;
    border-radius: 5px;
    display: inline-block;
    width: 100%;
}
.userReview span.userReviewImg {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #ff8213;
    overflow: hidden;
    float: left;
}
.userReview span.userReviewImg img.img-fluid {
    height: 40px;
    object-fit: contain;
}
.userReview span.userReviewContent {
    width: calc(100% - 60px);
    padding-left: 10px;
    float: left;
}

.userReview span.userReviewContent h4 {
    font-size: 18px;
    margin: 0px;
    margin-top: 5px;
    margin-bottom: 2px;
}
.userReview span.userReviewContent p {
    margin: 0px 0px 6px;
}
.userReview span.userReviewContent p a {
    color: #c1c1c1;
}
.userReview span.userReviewContent p a.active {
    color: #ff8213;
}
.userReview span.userReviewContent textarea{
    outline: none;
    box-shadow: none;
    border:1px solid #ccc;
}
.userReviewContent input.btn {
    background: #ff8213;
    color: #fff;
    box-shadow: none;
}
.userReviewContent input.btn:hover {
    background: #ec7102;
}
.userReview hr {
    width: 100%;
    float: left;
}
ul.listComment {
    display: inline-block;
    width: 100%;
    padding: 0px;
    margin-bottom: 0px;
}
ul.listComment li{
    display: inline-block;
    width: 100%;
    padding: 5px 0px;
    border-bottom: 1px solid #e5e5e5;
}
ul.listComment li span.userImgrevied {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #ff8213;
    overflow: hidden;
    float: left;
}
ul.listComment li span.userImgrevied img {
    height: 40px;
    object-fit: contain;
}
ul.listComment li span.userReviewContented {
    width: calc(100% - 60px);
    float: left;
    padding-left: 10px;
}
ul.listComment li span.userReviewContented h4 {
    font-size: 17px;
    margin-bottom: 2px;
}
ul.listComment li span.userReviewContented p.userP {
    font-size: 13px;
    margin-bottom: 5px;
}
ul.listComment li span.userReviewContented p.userP+p {
    margin-bottom: 0px;
}
ul.listComment li span.userReviewContented p a.active {
    color: #ff8213;
}
ul.listComment li span.userReviewContented p a {
    color: #c1c1c1;
}
ul.listComment li:last-child {
    border: 0px;
}
.reviewListOuter {
    margin-bottom: 15px;
}
span.address{
    height: 40px;
    font-size: 13px;
    overflow: hidden;
}
.cover_img img.img-fluid{
    height: 150px;
    width: 100%;
    object-fit: cover;
}
.owl-nav button.owl-prev,
.owl-nav button.owl-next{
    z-index: 1000;
}
.cuisinesname{
    background-color: #ff8213;
    display: inline-block;
    margin-right: 5px !important;
    margin-bottom: 5px !important;
    padding: 3px 10px;
    border-radius: 5px;
    color: #fff;
    font-size:14px;
    cursor: pointer;
}
.modalOpenBox{
    display: block;
    padding-right: 17px;
}
/* .modalOpenBox {
    background: rgba(21, 21, 21, 0.5098039215686274);
} */

div#root {
    display: inline-block;
    width: 100%;
}
.Checkout-item-content span.total-review {
    padding: 0px;
    font-size: 15px;
    word-break: break-word;
}

.modal, .modal-open {
    overflow-x: hidden !important;
overflow-y: auto !important;
}

button.addbtn {
    width: 100px;
    height: 36px;
    border-radius: 5px;
}
button.addbtn:hover {
    background: #FF8213;
    color: #fff;
}

.future-order span {
    margin-right: 5px;
}
.Beverage label {
    margin-right: 15px;
}
.user-product-outer {
    width: 50%;
    float: left;
}
.veg_pizza {
    width: 100%;
    display: inline-block;
    float: left;
}
.user-product-outer:first-child {border-right: 1px solid #ccc;}

.hereIScontactOuter {
    background: #fff;
    padding: 50px 60px;
    width: 50%;
    margin: 0px auto 100px;
    margin-top: -100px;
    z-index: 100;
    position: relative;
    box-shadow: 0px 0px 8px #ccc;
}
.formOuter h2, .ourInfo h2 {
    font-size: 27px;
    margin-bottom: 30px;
}
.formOuter .form-control {
    border-radius: 0px;
    border: 1px solid #e9e9e9;
    box-shadow: none !important;
    outline: none !important;
    font-size: 13px;
    min-height: 45px;
}
.btnOuter .btn {
    background: #FF8213;
    border-radius: 0px;
    font-size: 15px;
    min-width: 160px;
    color: #fff;
    padding: 10px 10px;
    box-shadow: none;
}
.btnOuter .btn:hover {
    background: #f37205;
}
.ourInfo p {
    padding-left: 28px;
    position: relative;
    font-size: 14px;
}
.ourInfo p i {
    position: absolute;
    left: 0px;
    top: 4px;
}
.ourInfo p span {
    display: block;
}
.contactUs .order-heading h2 {
    margin-bottom: 20px;
    display: block;
}
section.Order_Food {
    width: 100%;
    padding: 15px 0px 100px;
    display: flex;
    align-content: stretch;
}
.siderbarmenu-button button {
    background: #ff8213;
    border: 0px;
    border-radius: 2px;
    color: #fff;
    padding: 4px 10px;
    cursor: pointer;
    outline: none;
}
.custon-sidebar{
    display: inline-block;
    width: 250px;
    float: left;
    background: #f9f9f9;
    padding: 10px 0px;
    border-radius: 3px;
    border-right: 2px solid #e3e3e3;
}
.side_bar {
    position: initial;
    width: 100%;
    min-height: 100%;
    top: 0;
    left: 0;
    float: left;
    z-index: 99;
}
.Order_Food section.page {
    margin-left: 250px;
    height: 100%;
    padding: 0px 0px 0px 25px;
}
.open_now span.sidebar_left {
    font-weight: 500;
    color: #666666;
    position: relative;
    top: 3px;
    padding-bottom: 20px;
    display: inline-block;
}
.open_now{
    padding: 0px 10px;
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid #e8e2dc;
    overflow: hidden;
    float: left;
}

/* Swich Button */
.open_now .switch {
    width: 60px;
    height: 30px;
    display: inline-block;
    border-radius: 35px;
    overflow: hidden;
    position: relative;
    margin:0px;
    float: right;
}
.open_now .switch input {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
    padding: 0px 0px;
    margin: 0px 0px;
}
.open_now .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #c5c5c5;
    -webkit-transition: .4s;
    transition: .4s;
}
.open_now .slider:before {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    content: "";
    position: absolute;
    left: 4px;
    top: 4px;
    background-color: #ffffff;
    -webkit-transition: .5s;
    transition: .5s;
    text-indent: 30px;
    font-size: 14px;
    color: #2196F3;
}
.open_now .switch input:checked + .slider {
    background-color: #FF8213;
}
.open_now .switch input:checked + .slider:before {
    left: calc(100% - 26px);
    content: "";
    color: #ffffff;
    text-indent: -30px;
}
.listing_order {
    display: inline-block;
    width: 100%;
    padding: 0px 0px;
}
.listing_order .custom_check .check_indicator{
    border-radius: 5px;
    background: transparent;
}
.listing_order p {
    margin: 0px;
    padding: 7px 10px 8px;
    border-bottom: 1px solid #e8e2dc;
}
.listing_order p span.custom_check img {
    margin-right: 14px;
    max-width: 23px;
    position: relative;
    top: -1px;
}
.listing_order p span.custom_check {
    font-weight: 600;
    color: #666666;
    font-size: 14px;
    width: 100%;
}
span.check_indicator.main {
    background: #ff8213 !important;
    border-color: #ff8213;
}
.custom_check input:checked + .check_indicator.main:before{
    border-color: #fff;
}
.order-listing {
    float: left;
    width: 50%;
}
.order-listing h2 {
    color: #333333;
    font-size: 24px;
    margin-bottom: 5px;
}
.order-listing p {
    color: #666666;
    font-weight: 400;
    font-size: 14px;
}
.short-product {
    float: left;
    width: 50%;
    text-align: right;
}
.short-product select.custom-select {
    width: 170px;
    background-position: 94%;
    border: 1px solid #ccc;
    border-radius: 2px;
    margin-left: 10px;
    box-shadow: none;
    cursor: pointer;
}
.order-short {
    display: inline-block;
    width: 100%;
    margin-bottom: 5px;
}
.Order-progresh p {
    margin: 0px;
    padding: 10px 15px;
    font-weight: 600;
    color: #666666;
    font-size: 14px;
}
.Order-progresh p img {
    margin-right: 14px;
}
.progress {
    width: 90%;
    margin: 0 auto;
    background: #f1f1f1;
    border: 1px solid #ccc;
    border-radius: 35px;
    overflow: initial;
}
.progress-bar {
    background: #482823;
    position: relative;
    border-radius: 55px;
}
.progress-bar::after {
    content: "";
    width: 16px;
    height: 17px;
    background: #FF8213;
    border-radius: 50px;
    right: -5px;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
}
.Order-progresh {
    padding: 0px 0px 15px;
    border-bottom: 1px solid #e8e2dc;
    margin-bottom: 15px;
}
.repet-box {
    padding:10px 15px 10px;
    border-bottom: 1px solid #e8e2dc;
}
.repet-box p {
    margin: 0px;
    font-weight: 600;
    color: #666666;
    font-size: 14px;
}
.repet-box p img {
    margin-right: 13px;
    width: 21px;
    position: relative;
    top: -1px;
}
.repet-input {
    padding: 12px 0px 10px;
}
.repet-input  select.custom-select {
    background-position: 94%;
    border: 1px solid #ccc;
    box-shadow: none;
}
.select-order {
    padding: 13px 15px 5px;
    border-bottom: 1px solid #e8e2dc;
    margin-bottom: 17px;
}
.select-order p {
    margin: 0px;
    font-weight: 600;
    color: #666666;
    font-size: 14px;
}
.select-order p img {
    margin-right: 13px;
}
.order-type-select {
    display: inline-block;
    width: 100%;
    padding: 15px 0px 0px;
}
.order-type-select p {
    margin-bottom: 5px;
}
.order-type-select p span.custom_radio {
    font-weight: 400;
}
.repet-box.promotions {
    padding-top: 16px;
}
input.form-control.google-location-input {
    background-image: url(../img/location-sidebar.png);
    background-repeat: no-repeat;
    background-position: 95%;
    padding-right: 40px;
    box-shadow: none;
    border: 1px solid #ccc;
}
.repet-input input.form-control {
    box-shadow: none;
    border: 1px solid #ccc;
}
.repet-box p span.custom_radio {
    color: #cccccc;
    font-size: 16px;
}
span.up-sty {
    font-weight: normal;
    color: #999999;
}
.repet-box p span.custom_radio .active {
    color: #FF8213;
}
.reatings .repet-input p {
    margin: 10px 0px;
}
.filters-apply {
    display: inline-block;
    width: 80%;
    padding: 15px 10px;
}
.filters-apply button{
    padding: 10px 0px;
    cursor: pointer;
}

.filters-apply button:hover {
    transform: scale(1.05);
    box-shadow: 0px 0px 9.53px 1.47px rgba(178, 178, 178, 0.43);
    background: #ec6c00;
    border-color: #ec6c00;
    cursor: pointer;
}
.repet-box.promotions.reatings{
    padding-bottom: 50px;
}
.order-now button, .filters-apply button {
    width: 100%;
    background: #FF8213;
    border: 1px solid #FF8213;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    outline: none;
    transition: all 0.5s;
    position: relative;
    left: -2px;
}
.order-now button:hover {
    transform: scale(1.1);
    box-shadow: 0px 0px 9.53px 1.47px rgba(178, 178, 178, 0.43);
}
/***************12/19/2019******/
.reviewListOuter h3 {
    color: #ff8213;
}
.userReview {
    border: 1px solid #dee2e6;
    padding: 10px;
    border-radius: 5px;
    display: inline-block;
    width: 100%;
}
.userReview span.userReviewImg {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #ff8213;
    overflow: hidden;
    float: left;
}
.userReview span.userReviewImg img.img-fluid {
    height: 40px;
    object-fit: contain;
}
.userReview span.userReviewContent {
    width: calc(100% - 60px);
    padding-left: 10px;
    float: left;
}

.userReview span.userReviewContent h4 {
    font-size: 18px;
    margin: 0px;
    margin-top: 5px;
    margin-bottom: 2px;
}
.userReview span.userReviewContent p {
    margin: 0px 0px 6px;
}
.userReview span.userReviewContent p a {
    color: #c1c1c1;
}
.userReview span.userReviewContent p a.active {
    color: #ff8213;
}
.userReview span.userReviewContent textarea{
    outline: none;
    box-shadow: none;
    border:1px solid #ccc;
}
.userReviewContent input.btn {
    background: #ff8213;
    color: #fff;
    box-shadow: none;
}
.userReviewContent input.btn:hover {
    background: #ec7102;
}
.userReview hr {
    width: 100%;
    float: left;
}
ul.listComment {
    display: inline-block;
    width: 100%;
    padding: 0px;
    margin-bottom: 0px;
}
ul.listComment li{
    display: inline-block;
    width: 100%;
    padding: 5px 0px;
    border-bottom: 1px solid #e5e5e5;
}
ul.listComment li span.userImgrevied {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #ff8213;
    overflow: hidden;
    float: left;
}
ul.listComment li span.userImgrevied img {
    height: 40px;
    object-fit: contain;
}
ul.listComment li span.userReviewContented {
    width: calc(100% - 60px);
    float: left;
    padding-left: 10px;
}
ul.listComment li span.userReviewContented h4 {
    font-size: 17px;
    margin-bottom: 2px;
}
ul.listComment li span.userReviewContented p.userP {
    font-size: 13px;
    margin-bottom: 5px;
}
ul.listComment li span.userReviewContented p.userP+p {
    margin-bottom: 0px;
}
ul.listComment li span.userReviewContented p a.active {
    color: #ff8213;
}
ul.listComment li span.userReviewContented p a {
    color: #c1c1c1;
}
ul.listComment li:last-child {
    border: 0px;
}
.reviewListOuter {
    margin-bottom: 15px;
}
.breadcrumb-outer nav {
    display: inline-block;
    width: 70%;
}
.login-right+.download-content {
    width: 100%;
    display: inline-block;
    position: relative;
    z-index: 100;
    top: 38px;
    right: -170px;
}
.Order-progresh .input-range {
    margin-top: 21px;
}
.Order-progresh .input-range__slider{
    background: #FF8213;
    border: 1px solid #FF8213;
}
.Order-progresh .input-range__track--active {
    background: #FF8213;
}
.terms {
    text-align: left;
}
.terms h1 {
    text-align: left !important;
}
.terms {
    padding: 20px 0px;

}
header.inner-header + .main{
	border-bottom: 2px solid #ff8213;
}
.user-product-outer:nth-child(even) {border-right: 1px solid #dee2e6;}
.menuBar {
    width: 85px;
    float: left;
 	padding: 2px 0px;
 	cursor: pointer;
}
.cartOuter {
    position: fixed;
    bottom: 50px;
    z-index: 1000;
    background: #f83615;
    right: 20px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 50%;
    cursor: pointer;
    font-size: 23px;
}
.cartOuter span.countProduct {
    position: absolute;
    top: -11px;
    right: -2px;
    font-size: 11px;
    background: #d93a2b;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
a.float-right {
    cursor: pointer;
}
/*******profile pagen css start****/
.cover_img {
    position: relative;
}
.cover_img .userProfile {
    position: absolute;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 5px solid #e6e6e6;
    bottom: -30px;
    left: 15px;
    z-index: 100;
}
.cover_img .userProfile>img {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    object-fit: cover;
    object-position: top;
}
.cover_img .userProfile span.uploadImg {
    position: absolute;
    bottom: 0px;
    right: 15px;
    cursor: pointer;
}
.cover_img .userProfile span.uploadImg input[type="file"] {
    width: 27px;
    height: 27px;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    opacity: 0;
    z-index: 100;
}
.massageDiscount {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.massageDiscount .open_now {
    border: 0px;
    width: auto;
}
.userInfoProfile {
    padding-left: 182px;
    margin-top: -73px;
    z-index: 10;
    padding-right: 15px;
    position: relative;
}
.userInfoProfile h2.userProfileName {
    margin-bottom: 80px;
    font-size: 28px;
    font-weight: bold;
    color: #fff;
    text-shadow: 1px 2px 4px #000;
}
.userInfoProfile p {
    font-size: 14px;
}
.profileWreperOuter {
    border: 1px solid #ccc;
}
.userInfoProfile .form-control {
    border: 0px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 21px;
    border-radius: 0px;
    box-shadow: none;
    outline: none;
    padding-left: 0px;
}
.updateEmail {
    text-align: right;
    margin-bottom: 25px;
}
.updateEmail button.btn {
    border-radius: 0px;
    background: #FF8213;
    color: #fff;
    outline: none;
    box-shadow: none;
}
.updateEmail button.btn:hover {
    background: #e26c09;
}
.logo img {
    width: 75px;
}
.leftSidebarOuter{
	padding: 0px;
    margin-bottom: 20px;
}
.leftSidebarOuter ul{
	border: 0px !important;
	margin:-1px 0px 0px;
	list-style: none;
}
.leftSidebarOuter ul li{
	width: 100%;
	padding: 10px 15px;
}
.leftSidebarOuter ul li a{
	display: inline-block;
	width: 100%;
}
.leftSidebarOuter ul li.react-tabs__tab--selected,
.leftSidebarOuter ul li:hover,
.leftSidebarOuter ul li:hover a{
	background: #ff8213;
	border-radius: 0px;
	color: #fff !important;
	border-color: #fff;
}
.leftSidebarOuter ul li a {
    color: #212529;
    text-decoration: none;
}
.leftSidebarOuter .react-tabs__tab-list li{
    border-bottom: 1px solid #ddc3ac;
}
.currentOrderOuter ul {
    padding: 0px;
    margin: 0px;
}
.currentOrderOuter ul li {
    display: inline-block;
    width: 100%;
	box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.16);
	border-radius: 5px;
}
.currentOrderOuter ul li .currentOrderUserinfo {
    width: 100%;
    display: inline-block;
    padding:15px 10px 10px 10px;
    position: relative;
    min-height: 115px;
}
.currentOrderUserinfo span.currentuserimg {
    width: 80px;
    float: left;
    height: 80px;
    float: left;
}
.currentOrderUserinfo span.currentuserimg img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
}
.currentOrderUserinfo span.currentuserContent {
	width: calc(100% - 80px);
	padding-left: 15px;
	float: left;
}
.currentOrderUserinfo span.currentuserContent h3 {
    font-size: 20px;
    margin-bottom: 2px;
    color: #3d3d3d;
    width:100%;
}
.previouseOuter .currentOrderUserinfo span.currentuserContent h3{
    width:70%;
}
.currentOrderUserinfo span.currentuserContent p {
    color: #3d3d3d;
    font-size: 15px;
    margin-bottom: 3px;
}
.currentOrderUserinfo span.currentuserContent h4 {
    margin: 0px;
    font-size: 17px;
    color:#000;
}
.currentOrderUserinfo span.currentuserContent h4 span {
    color: #9d9d9d !important;
}
.currentOrderUserinfo span.currentuserContent h4 span {
    color: #9d9d9d;
}
.currentOrderUserinfo {
    display: inline-block;
    width: 100%;
}
.itemOuterBox {
    display: inline-block;
    width: 100%;
    border-top: 1px solid #ececec;
    padding: 5px 15px;
}
.itemOuterBox h5 {
    margin-bottom: 2px;
    font-size: 16px;
}
.itemOuterBox p {
    margin: 0px;
    color: #3d3d3d;
    font-size: 15px;
    min-height: 40px;
}
.cancelOrder {
    background: #ededed;
    text-align: center;
    border-radius: 0px 0px 5px 5px;
}
.cancelOrder a {
    display: inline-block;
    width: 100%;
    padding: 5px 0px;
    color: #b72628;
    text-decoration: none;
    font-weight: bold;
}
.cancelOrder a:hover {
    color: #fff;
    background: #ff8213;
    border-radius: 0px 0px 5px 5px;
}
a.canceledOrder {
    position: absolute;
    right: 20px;
    top: 0px;
    color: #b72628;
    text-decoration: none;
    font-weight: bold;
    font-size: 14px;
}
.orderstatusBar {
    border: 1px solid #e9ecef;
    padding: 15px;
    border-radius: 5px;
}
ul.orderstatuslisting {
    padding: 0px;
    list-style: none;
}
ul.orderstatuslisting li {
    margin-left: 55px;
    border-bottom: 1px solid #e9ecef;
    padding-bottom: 20px;
    position: relative;
}
ul.orderstatuslisting li:last-child{
    border: 0px;
}
ul.orderstatuslisting li:before {
    content: "";
    position: absolute;
    top: 0px;
    bottom: -1px;
    left: -40px;
    background: #ff8213;
    width: 7px;
}
ul.orderstatuslisting li.complate+li:before,
ul.orderstatuslisting li.complate+li:after,
ul.orderstatuslisting li.complate+li+li:before,
ul.orderstatuslisting li.complate+li+li:after,
ul.orderstatuslisting li.complate+li+li+li:before,
ul.orderstatuslisting li.complate+li+li+li:after,
ul.orderstatuslisting li.complate+li+li+li+li:before,
ul.orderstatuslisting li.complate+li+li+li+li:after,
ul.orderstatuslisting li.complate+li+li+li+li+li:before,
ul.orderstatuslisting li.complate+li+li+li+li+li:after {
    background: #ccc;
}
ul.orderstatuslisting li.complate.ddd:before{
    background: #ccc !important;
}
ul.orderstatuslisting li.complate.ddd:last-child:before{
    background: #ff8213 !important;
}
/*
ul.orderstatuslisting li.complate:after {
    background: #ff8213;
} */

ul.orderstatuslisting li:after {
    content: "";
    position: absolute;
    width: 19px;
    height: 19px;
    background: #ff8213;
    left: -46px;
    top: 0px;
    border-radius: 50%;
}
ul.orderstatuslisting li h5 {
    padding-top: 20px;
}
ul.orderstatuslisting li:first-child:before {
    border-radius: 5px 5px 0px 0px;
}
ul.orderstatuslisting li:last-child:before {
    border-radius: 0px 0px 5px 5px;
}
.currentOrderUserinfo.detailsBox {
    display: inline-block;
    width: 100%;
    background: #e9ecef;
    padding: 12px;
    border-radius: 5px;
    margin-bottom: 5px;
}
h4.mainHeading {
    font-size: 32px;
    margin-bottom: 10px;
    color: #472823;
}
.currentOrderUserinfo h4.summeryprice {
    display: block;
    float: left;
    width: 100%;
    margin-top: 10px;
    font-size: 21px;
    padding-bottom: 5px;
}
.currentOrderUserinfo h4 span {
    color: #9d9d9d;
}
.orderSummary .currentOrderUserinfo{
    margin-bottom: 0px;
    padding: 5px 15px;
    border: 1px solid #e9ecef;
    border-radius: 5px;
}
.orderSummary .itemOuterBox {
    padding: 8px 15px;
}
.orderSummary .itemOuterBox h5 {
    font-size: 20px;
}
.orderSummary .itemOuterBox p {
    font-size: 16px;
}
.itempriceouter {
    border-top: 1px solid #ececec;
    padding: 10px 0px 10px 15px;
}
.itempriceouter p {
    margin: 0px;
    font-size: 18px;
    padding: 4px 0px;
    color: #a9a9a9;
}
.itempriceouter p span.rightprice {
    float: right;
    color: #333;
    font-weight: 600;
}
.itempriceouter p span.rightprice span {
    color: #a9a9a9;
    font-weight: normal;
}
p.totalOuter {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 10px 0px;
    margin-top: 10px;
    color: #333;
    font-weight: 600;
}
span.allPrices {
    color: #91aecb;
    padding: 5px 0px 0px 0px;
    display: block;
}
.ordercontent {
    padding: 20px 15px 0px;
}
.ordercontent p {
    font-size: 15px;
}
.currentOrderOuter {
    margin-bottom: 25px;
}
.currentuserimg {
    width: 100px;
    float: left;
}
.currentuserimg img {
    width: 100%;
}
.Restaurants_list.p-2 .Checkout-item-content {
    width: calc(100% - 100px);
    position: relative;
}
span.productlineImg {
    width: 50px;
    float: left;
}
.owl-nav.disabled{
    display: block !important;
}
.currentOrderOuter ul li a {
    color: #fd8212;
}
.delivery-location select {
    border: 0px;
    outline: none;
    -webkit-appearance: none;
    width: 100%;
    color: #ff8213;
    background: transparent;
}
.logo-outer img {
    max-width: 150px;
}
.login-right a.nav-link {
    padding: 0px;
}
.deliverTo {
    border: 1px solid #e9ecef;
    padding: 15px;
    border-radius: 5px;
}
.deliverTo h5 {
    text-transform: uppercase;
    margin-bottom: 0px;
    font-weight: 600;
}
.deliverTo p {
    margin: 0px;
    font-weight: 600;
}
.deliverTo span {
    color: #909090;
}
.cancelOrderBtn {
    padding: 15px 0px 0px;
}
.cancelOrderBtn button {
    background: #e0e0e0;
    color: #000;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    padding: 9px 25px;
    font-weight: bold;
    cursor: pointer;
}
.cancelOrderBtn button:hover {
    background: #ffffff;
    color: #f34a38;
    border: 1px solid #f34a38;
}
.menuBar span {
    background: #ff8213;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    left: 13px;
    top: 4px;
    box-shadow: 0px 0px 19.53px 1.47px rgba(0, 0, 0, 0.23);
}
.menuBar img {
    width: 50px;
}
.Restaurant_overview .react-tabs {
    border: 1px solid #dee2e6;
}
.overviewmenuOuter {
    padding: 0px 15px 0px;
}
.Restaurant_overview ul.react-tabs__tab-list {
    background: #ff8213;
}
.Restaurant_overview .react-tabs__tab-list li{
    color: #fff;
    border: 0px;
}
.overviewmenuOuter .veg_pizza{
    margin-bottom: 10px !important;
    margin-top: 0px !important;
}
.overviewmenuOuter .catrgory_search {
    margin-bottom: 10px !important;
}
.offer_outer {
    margin-bottom: 20px;
    display: inline-block;
    width: 100%;
}
.Restaurant_overview .offer_time .min-order {
    width: 100%;
}
.min-order.deliverych {
    padding: 0px;
}
.minodr span, .deliverych span {
    color: #333;
}
.minodr span span, .deliverych span span {
    color: #a7a7a7;
}
.overviewenuOuter {
    padding: 0px 15px;
}
.swal2-confirm {
    background: #ff8213 !important;
}
.swal2-confirm:focus, .swal2-styled.swal2-cancel {
    outline: none !important;
    box-shadow: none !important;
}

.overlay_loader{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0,0,0,.7);
    z-index: 9999;
  }

  .overlay_loader .overlay_div{
    position: absolute;
    width: 300px;
    height: 300px;
    left: 50%;
    margin-left: -150px;
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    top: 50%;
    z-index: 9999;
    border-radius: 5px;
    text-align: center;
  }

  .overlay_loader .loader_img{
    width: 250px;
  }

  .overlay_div span{
    font-size: 20px;
    display: block;
    color: #273a4a;
  }
  .Delivery_location .Restaurants_list.p-2 .Checkout-item-content {
    width: calc(100% - 40px);
}
.orderplacedouterbox .custon-sidebar {
    background: #fff;
    border: 0px;
    width: 100%;
    padding: 0px;
}
.orderplacedouterbox .container {
    background: transparent;
}
.min-order.orderPriceing:before {
    content: "";
    position: absolute;
    right: 5px;
    border-right: 1px dashed #ccc;
    top: 4px;
    bottom: 0px;
    height: 30px;
}
.search-outer .delivery-location span img {
    width: 30px;
    height: 20px;
    object-fit: contain;
    position: relative;
    left: -12px;
    top: -4px;
}
.arrowhidden{
    visibility: hidden;
}
.locationHeaderuser {
    display: block;
    width: 100%;
}
.locationHeaderuser a {
    color: #ff8213;
    font-size: 17px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    margin: 26px 0px 0px;
    border-bottom: 2px solid #ff8213;
    position: relative;
    left: 50px;
    text-decoration: none;
    font-weight: bold;
}
.searchsilder, .searchsilder .product_name {
    width: 100%;
}
section.categoryMain {
    background: #efefef;
    padding: 8px 0px 0px;
}
.searchsilder .product_name .owl-nav button.owl-prev,
.searchsilder .product_name .owl-nav button.owl-next {
    background: transparent !important;
}
.Customization_product_img {
    text-align: center;
}
.Customization_product_img img {
    height: 120px;
}
section.myBasketOuter {
    padding: 20px 0px;
}
.myBasketinner{
    display: inline-block;
    width: 100%;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    padding:10px;
    background: rgb(255, 251, 248);
}
span.userImgBasket {
    width: 80px;
    height: 80px;
    float: left;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
span.usercontentBasket, ul.restaurantList li span.restaurantContantouter {
    width: calc(100% - 80px);
    float: left;
    padding-left: 15px;
}
.usercontentBasket h4 {
    font-size: 20px;
    margin-bottom: 3px;
}
.usercontentBasket p {
    font-size: 15px;
}
.myBasketOuter h2 {
    margin-bottom: 20px;
    color: #472823;
}
.userImgBasket img,
ul.restaurantList li span.restaurantIMgouter img {
    max-width: 80px;
    max-height: 80px;
    object-fit: contain;
}
ul.restaurantList {
    padding: 0px;
    list-style: none;
    margin: 0px;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    display: inline-block;
    width: 100%;
}
ul.restaurantList li {
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid #e8e8e8;
    padding: 10px;
    float: left;
}
.searchBoxFlip .search-outer {
    width: 82%;
    margin: 0 auto 15px;
}
ul.restaurantList li span.restaurantIMgouter {
    width: 80px;
    float: left;
    height: 80px;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
span.restaurantContantouter h4 {
    font-size: 19px;
    margin-bottom: 0px;
}
span.price {
    font-size: 20px;
    color: #000;
    font-weight: bold;
    text-transform: uppercase;
}
span.price span{
    color: #73726e;
}
.restaurantContantouter p {
    margin-bottom: 0px;
}
.restaurantContantouter span.add-remove-outer {
    margin-top: -40px;
}
ul.restaurantList li:last-child {
    border-bottom: 0px;
}
.totalOuter {
    display: inline-flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    background: #ff8213;
    padding: 9px 10px;
}
.totalOuter span.totalpayment {
    color: #453c33;
    font-weight: 600;
}
span.confirmorder {
    display: inline-block;
}
span.confirmorder a {
    color: #ff8213;
    background: #fff;
    padding: 7px 15px;
    border-radius: 55px;
    font-weight: 400;
    margin: 0px;
    display: inline-block;
    text-decoration: none;
}
.logo {
    display: flex;
    align-items: center;
}
.searchsilder .product_name button.owl-prev {
    left: -15px;
}
.searchsilder .product_name button.owl-next {
    right: -5px;
}
span.mobilelocation {
    display: none;
}
.searchBoxFlip .search-outer .delivery-location{
    padding: 10px 20px;
}
.searchBoxFlip .search-right span .btn{
    padding: 8px;
}
.searchBoxFlip .search-right span::before{
    left: -50px;
}
.searchBoxFlip .search-right::after{
    height: 52px;
    right: -60px;
}
.locationmodule .modal-content {
    background: #ffffff;
}
.locationmodule .form-control {
    border-radius: 0px;
    box-shadow: none;
    border: 1px solid #ccc;
    width: calc(100% - 0px);
    float: left;
}
.locationmodule .modal-body button {
    background: #ff8213;
    padding: 6px 10px;
    border: 1px solid #ff8213;
    border-radius: 2px;
    color: #fff;
    cursor: pointer;
    outline: none;
    width: 100px;
    margin-left: 10px;
}
ul.MyFavoriteOrderlist {
    padding: 0px;
    list-style: none;
    margin: 0px;
    border: 1px solid #e9ecef;
    border-radius: 5px;
    background: #fff;
}
h4.mainHeading{
    background: #fff;
}
.MyFavoriteOrderlist li {
    border-bottom: 1px solid #e9ecef;
}
.MyFavoriteOrderlist li:last-child {
    border: 0px;
}
.inputmodalouter {
    display: inline-block;
    width: 100%;
}
.modalOpenBox .modal-body {
    min-height: 360px;
}
.Delivery_table {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
#popular_product .owl-nav button, #food-add .owl-nav button,
.food-collection #food-category .owl-nav button {
    background: #fd8212 !important;
    padding: 10px 5px 0px !important;
}
.food-collection #food-category .owl-nav button {
    padding: 7px 5px 10px !important;
}
#food-add .owl-nav button span, .food-collection .owl-nav button span{
    color: #fff;
}
.menuBar span img{
    -webkit-transition: -webkit-transform .5s;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
}
.menuBar span:hover img {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
}

a.loginback {
    background: #fd8212;
    color: #fff;
    text-decoration: none;
    position: relative;
    left: 5px;
    top: 7px;
    padding: 7px 12px;
    border-radius: 55px;
    font-size: 20px;
}
.login-outer select.form-control.form-control {
    height: 48px;
}
.mobileNumberOuter {
    display: flex;
    position: relative;
}
.mobileNumberOuter .form-group.input-outer.countryCode {
    width: 100px;
}
.mobileNumberOuter .form-group.input-outer.mobileright {
    width: calc(100% - 110px);
    margin-left: 10px;
}
.login-form .text-danger {
    text-align: left;
}
.mobileNumberOuter .form-group.input-outer.mobileright .text-danger {
    left: -109px;
    position: relative;
}
.filterbtns {
    display: flex;
    align-items: center;
    justify-content: center;
}
.filterbtns button.resetBtn {
    float: right;
    background: #fd8212;
    border: 1px solid #fd8212;
    padding: 5px 5px;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    border-radius: 55px;
    outline: none;
    margin: 0px 5px 10px;
    width: 100%;
}
.pickupDelivery {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 7px;
}
.pickupDelivery span.sidebar_left {
    margin-right: 10px;
}
.modal-body.mapOuter {
    padding: 0px;
}
.locationmodule .modal-lg {
    max-width: 70%;
}
.modal-body.mapOuter iframe {
    width: 100%;
    height: 500px;
}
.location-reating a {
    color: #fd8212;
    text-decoration: underline;
}
.addressOuterMap input.location-search-input {
    width: 100%;
    outline: none;
    padding: 5px;
    border: 1px solid #ccc;
}
.addressOuterMap>div {
    position: relative;
}
.addressOuterMap>div .autocomplete-dropdown-container {
    position: absolute;
    top: 31px;
    background: #fff;
    left: 0px;
    right: 0px;
    padding: 0px 10px;
}
.Material .card-body {
    padding: 0px 1.25rem;
}
.Material .card-header button.btn{
    padding: 7px 15px;
}
.Food_basket .product-right-content p{
    min-height: initial;
    width:80px;
}
.Food_basket .product-right-content p.custitemview {
    min-height: initial;
    width: 170px;
    position: relative;
}
.Food_basket .product-right-content p.custitemview span{
    display: inline-block;
    color: #7d7d7d;
}
.Food_basket .product-right-content p.custitemview span.custitile {
    color: #000;
}
.Food_basket .product-right-content p.custitemview span span {
    margin-right: 2px;
}
span.date .react-datepicker-wrapper {
    width: 100%;
}
.mapMOdalBox h5 {
    color: #fd8212;
}
.mapMOdalBox input.form-control {
    border-radius: 3px;
}
.mapMOdalBox h6 {
    margin-top: 10px;
}
.mapMOdalBox ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
    border: 1px solid #f1f1f1;
    border-radius: 5px;
}
.mapMOdalBox ul li {
    padding: 15px;
    border-bottom: 1px solid #f1f1f1;
    padding-right: 60px;
    position: relative;
}
.mapMOdalBox ul li a {
    color: #fd8212;
    text-decoration: none;
}
.verifyaddress {
    position: absolute;
    right: 0px;
    top: 31%;
    transform: translateX(-50%);
    font-size: 36px;
}
.edittext a {
    background: #fd8212;
    margin-right: 8px;
    padding: 6px 10px;
    border-radius: 5px;
    color: #fff !important;
}

.edittext a:hover {
    background: #ec7407 !important;
}
.myaddressmodal .modal-header {
    background: #fd8212;
}
.myaddressmodal .modal-header h5 {
    color: #fff;
}
.newAddressBTN {
    text-align: center;
}
.newAddressBTN button{
    width: auto !important;
    margin-top: 13px;
    margin-left: 0px !important;
}
.newAddressBTN button:hover {
    background: #e47007;
    border-color: #e47007;
}
.mapMOdalBox label {
    border-bottom: 1px solid #dadada;
    display: block;
    padding: 5px;
    cursor: pointer;
}
.mapMOdalBox ul li a p {
    color: #000;
}
.searchOuterModal {
    display: flex;
}
ul.CouponCodeListModal li {
    padding: 15px;
}
ul.CouponCodeListModal li span {
    display: inline-block;
    background: #fd82122b;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: bold;
}
ul.CouponCodeListModal li span img {
    width: 60px;
    margin-right: 35px;
}
ul.CouponCodeListModal li div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
ul.CouponCodeListModal li h5 {
    margin-top: 12px;
    font-size: 18px;
    color: #000;
    margin-bottom: 0px;
}
ul.CouponCodeListModal li p {
    margin: 0px;
    text-align: center;
    border-top: 1px solid #f1f1f1;
    margin-top: 21px;
    padding-top: 10px;
}
.helpdeatil {
    float: right;
    color: #ff8213;
}
.addressOuterMap .form-group {
    display: inline-block;
    width: 100%;
}
.addressOuterMap label {
    border: 0px;
    padding: 0px;
}
.deliveryBoyInfo {
    border: 1px solid #e9ecef;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 15px;
    display: flex;
}
.deliveryBoyInfo span.boyImgInfo {
    width: 70px;
    height: 70px;
    overflow: hidden;
}
.deliveryBoyInfo span.boyImgInfo img {
    max-width: 100%;
    width: 70px;
    height: 70px;
    object-fit: contain;
}
.deliveryBoyInfo span.boycontentinfo {
    padding-left: 15px;
    width: calc(100% - 70px);
}
.boycontentinfo h5 {
    margin-bottom: 3px;
}
.boycontentinfo p {
    margin-bottom: 5px;
}
ul.orderstatuslisting li button.trackBtn.btn {
    position: absolute;
    top: 25px;
    right: 0px;
}
.notificationOuter {
    display: inline-block;
    width: 100%;
}
.clearAllOuter>a {
    display: inline-block;
    float: right;
    color: #fd8212;
    text-decoration: none;
}
.clearAllOuter>a i {
    color: red;
    margin-right: 7px;
}
ul.notificationOuterList {
    display: inline-block;
    width: 100%;
    padding: 0px;
    margin: 0px;
}
.clearAllOuter {
    display: inline-block;
    width: 100%;
    border-top: 1px solid #e9ecef;
    border-bottom: 1px solid #e9ecef;
    padding: 5px 0px;
}
.clearAllOuter {
    display: inline-block;
    width: 100%;
    border-top: 1px solid #e9ecef;
    border-bottom: 1px solid #e9ecef;
    padding: 5px 0px;
}
ul.notificationOuterList li {
    display: inline-block;
    width: 100%;
    background: #e9ecef;
    padding: 12px;
    border-radius: 5px;
    margin-bottom: 5px;
}
ul.notificationOuterList li h5 {
    margin-bottom: 4px;
}
ul.notificationOuterList li p {
    margin: 0px;
    font-size: 14px;
}
ul.notificationOuterList li span.notificationTime {
    font-size: 14px;
    position: relative;
    top: 5px;
}
.Restaurants_list.p-2 .Checkout-item-content .social_item {
    float: right;
    position: absolute;
    top: 0px;
    right: 0px;
}
iframe#launcher {
    right: 70px !important;
}
a.cancleOrdertext:hover {
    background: #ededed;
    color: #dc3545!important;
    cursor: default;
}
.food-outer.fooddisble::before {content: "";position: absolute;left: 0px;right: 0px;top: 0px;bottom: 0px;z-index: 100;background: rgba(0, 0, 0, 0.15);}

.social-outer button {
    background: #fff;
    padding: 5px 15px;
    border: 1px solid #ececec;
    border-radius: 5px;
    margin: 0px 5px;
}
.ammount p span span, .total-ammount p span span, .total-ammount span span {
    color: #a7a7a7;
    margin-left: 4px;
}
.commentRating span.userReviewContent p {
    margin: 0px;
}
.commentRating span.userReviewContent p label {
    font-size: 24px;
    margin: -4px 0 0 0;
    height: 31px;
}
.commentRating span.userReviewContent p {
    margin: 0px;
}
.commentRating span.userReviewContent p label {
    font-size: 24px;
    margin: -4px 0 0 0;
    height: 31px;
}
.commentRating span.userReviewContent p label.dv-star-rating-star.dv-star-rating-full-star {
    color: #fd8212 !important;
}
.commentRating .modal-header h5 {
    text-align: left;
}
.commentRating .modal-body {
    min-height: inherit;
    padding-bottom: 30px;
}
.currentOrderOuter ul li.previouseOuter h3 {
    font-size: 18px;
}
.useimg{
    width: 67px;
    float: left;
    vertical-align: middle;
    border-style: none;
}
.homeAddressMOdal .centerBtn.text-center button.btn {
    margin: 0px;
    height: 60px;
    font-size: 21px;
    border-radius: 0px 2px 2px 0px;
    box-shadow: none !important;
    outline: none !important;
    width:150px;
}
.homeAddressMOdal .addressOuterMap .flexOuterMain>div {
    display: flex;
}
.modalBoxContent a {
    display: inline-block;
    margin-bottom: 30px;
}
.modalBoxContent h2 {
    font-size: 40px;
    text-transform: capitalize;
}
.modalBoxContent p {
    font-size: 22px;
    color: #9e9e9e;
}
.modalBoxContent2 {
	margin-top: 20px;
}
.homeAddressMOdal .modal-content .modal-body {
    background: #fff;
    padding:25px 25px 120px;
    border-radius: 5px;
}
.homeAddressMOdal .modal-content{
    padding:20px;
    background: #fd8212;
}
.homeAddressMOdal .modal-header button.close{
    right:-14px;
    top:-14px;
}
.flexOuterMain input.location-search-input {
    height: 60px;
    padding: 5px 25px;
}
.homeAddressMOdal .addressOuterMap>div .autocomplete-dropdown-container{
    top:61px;
}
span.locationMapicon {
    position: absolute;
    top: 37px;
    z-index: 1000;
    font-size: 25px;
    color: #fff;
    right: 6%;
}
.dboyrating {
    display: inline-block;
    width: 100%;
    background: #efefef;
    margin: 10px 0px 0px;
    padding: 10px 15px;
    border-radius: 5px;
    border:1px solid #fd8212;
}
.dboyrating h3 {
    font-size: 15px;
    background: #fd8212;
    display: inline-block;
    padding: 9px 15px;
    border-radius: 0px 0px 15px 15px;
    margin-top: -5px;
    margin-bottom: 7px;
    position: relative;
    top: -5px;
    color: #fff;
}
.dboyrating textarea.form-control {
    background: #f3f3f3;
    border: 1px solid #cecece!important;
    border-radius: 5px !important;
}
/*.modalOpenBox {
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    display: flex;
}*/
.modalOpenBox .modal-dialog.modal-lg {
    min-width: 80%;
}
.currentOrderUserinfo span.currentuserContent p span span{
    color: #9d9d9d !important;
}
.pickupDelivery .switch {
    width: 70px;
    height: 30px;
    margin: 5px 5px;
}
.pickupDelivery .switch .slider:before{
    height: 26px;
    width: 26px;
    top: 2px;
    left:2px;
}
.pickupDelivery .switch input:checked + .slider:before {
    left: calc(100% - 28px);
}
.currentOrderOuter.prvodrhide li::before {content: "";position: absolute;left: 0px;right: 0px;top: 0px;bottom: 0px;background: #ccc;z-index: 100;border-radius: 5px;opacity: 0.5;}

.currentOrderOuter.prvodrhide li {
    position: relative;
}
.formCenterDiv {width: 70%;}
.formCenterDiv .form-control{
    box-shadow: none !important;
    border: 1px solid #ccc !important;
    height:45px;
}
.mapMOdalBox ul.CouponCodeListModal li {
    padding: 10px 0px 0px;
}
.mapMOdalBox ul.CouponCodeListModal li p {
    color: #fd8212;
    font-weight: bold;
    border: 1px solid #ccc;
    padding: 8px 5px;
}
.modal-body.mapMOdalBox ul.CouponCodeListModal {
    border: 0px;
}

/*******Table section css start*******/

.tableSections .repet-box p {
    border-bottom: 0px;
    padding-bottom: 0px;
}
.tableresevrationOuter .food-img {
    padding: 0px;
    overflow: hidden;
}
.tableresevrationOuter .food-img img{
    border-radius: 0px;
}
.food-outer.tableresevrationOuter h3 img{
    width: 20px;
    position: relative;
    top: -3px;
    margin-right: 3px;
    display: inline-block;
}
.food-content a p img {
    display: inline-block !important;
    width: auto !important;
    margin-right: 10px;
    position: relative;
    top: -2px;
}
.food-outer.tableresevrationOuter h3 {
    font-size: 18px;
    font-weight: normal;
    padding: 10px 10px;
    margin: 0px;
    background: #ff8213;
    color: #fff;
}
.restaurantAddress {
    padding: 7px 10px;
    font-size: 14px;
    border-bottom: 1px solid #e2e2e2;
}
.restaurantAddress p i {
    color: #ff8213;
}
.restaurantAddress p {
    margin: 0px;
    min-height: 60px;
}
.costOuter {
    position: relative;
    padding: 5px 5px 5px 27px;
    font-size: 15px;
    margin-bottom: 6px;
    border-bottom: 1px solid #e2e2e2;
}
.costOuter i {
    position: absolute;
    left: 9px;
    font-size: 24px;
    color: #ff8213;
    top: 10px;
}
.costOuter p {
    margin: 0px;
}
.costOuter p span {
    color: #ccc;
}
.order-now button:hover {
    transform: scale(1.05);
    box-shadow: 0px 0px 9.53px 1.47px rgba(178, 178, 178, 0.43);
}

.card-group>.card+.card {
    margin-left: 0;
    border-left: 0;
    background: #fd8416 !important;
    margin: 0px;
}
.login-body {
    background-image: url(../img/banner.png);
    background-size: 100% 100%;
    height: 100vh;
    display:flex;
    align-items: center;
    justify-content: center;
}
.login-body .card-group>.card+.card .text-center.card-body {
    display: flex;
    align-items: center;
    justify-content: center;
}
.card.bg-primary {
    border-color: #ff8417;
}
.login-body .form-group.input-outer {
    width: 100%;
}
.login-body h1{
    font-size: 30px;
}
.login-body button.px-4.btn.btn-primary {
    background: #fd8416;
    border: 1px solid #fd8416;
    box-shadow: none !important;
    outline: none !important;
}
.backLogin a {
    color: #fd8416;
    text-decoration: none;
}
span.location .dropdown{
    background: #4b2d27;
    border-radius: 5px;
    padding: 0px 0px 0px 10px;
    margin: 0px 0px;
    height: 31px;
}
.dropdown select.custom-select option {
    color: #000;
}
.flexOuterBox {
    display: flex;
}
.flexOuterBox .itemOuterBox {
    padding-top: 16px;
}
.upcominginner {
    display: flex;
}
.upcomingProductImg {
    width: 100px;
    height: 100px;
}
.upcominginner {
    display: flex;
    padding: 15px 10px 10px;
    border-bottom: 1px solid #e9ecef;
}
.upcomingProductImg {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    background: #fd8212;
}
.upcomingProductImg img {
    width: 100px;
    height: 100px;
    object-fit: contain;
    object-position: center;
}
.upcomingproductcontent {
    width: calc(100% - 100px);
    padding-left: 15px;
}
.upcomingproductcontent p {
    font-size: 17px;
    margin-bottom: 7px;
}
.remberOuter {
    padding: 6px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.remberOuter .open_now{
    border: 0px;
    width: auto;
}
.moreopction a {
    height: 50px;
    width: 50px;
    display: inline-flex;
    border: 1px solid #e9ecef;
    margin-right: 5px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
}
.moreopction a:hover {
    box-shadow: 0px 2px 11px #e9ecef;
}
.remberOuter .open_now .switch {
    height: 38px;
}
.remberOuter .open_now .slider:before {
    width: 31px;
    height: 31px;
}
.remberOuter .open_now .switch input:checked + .slider:before {
    left: calc(100% - 35px);
}
.upcomingproductcontent h4 {
    font-size: 32px;
    color: #4b2d27;
}
.remberOuter .open_now label {
    position: relative;
    top: 5px;
}
/**********Table Reservation pages css start*****/
.bookTable h3 {
    font-size: 18px;
    background: #ffedde;
    padding: 10px 7px;
    width: 100%;
    margin-bottom: 10px;
}
.bookTable {
    border: 1px solid #ddc3ac;
    padding: 2px;
}
.location_search input.form-control{
    background-size: 15px;
}
.outerPding, .bookTable .location_search {
    padding: 0px 7px;
}
span.tableinputOuter {
    position: relative;
    display: inline-block;
    width: 100%;
}
span.tableinputOuter input, span.tableinputOuter textarea{
    box-shadow: none !important;
    border:1px solid #dee2e6 !important;
}
span.tableinputOuter i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 11px;
    color: #ff8213;
    font-size: 17px;
}
.outerPding .open_now {
    width: 100px;
    float: right;
    border: 0px;
}
.form-group.outerPding span.custom_check {
    font-size: 12px;
    line-height: 17px;
}
.bookTable button.tblcmbtn {
    width: 100%;
    background: #ff8213;
    border: 1px solid #ff8213;
    padding: 4px 20px;
    border-radius: 5px;
    box-shadow: none;
    cursor: pointer;
    color: #fff;
    font-size: 17px;
    line-height: 30px;
    outline: none;
    transition: all 0.5s;
    text-transform: uppercase;
}
.bookTable button.tblcmbtn:hover{
    transform: scale(1.05);
    box-shadow: 0px 0px 9.53px 1.47px rgba(178, 178, 178, 0.43);
    background: #ec6c00;
    border-color: #ec6c00;
}
.tableresevrationOuter .orderPriceing strong span span {
    margin-left: 5px;
    display: inline-block;
}
.tableresevrationOuter .orderPriceing strong{
    font-size: 15px;
}
.tableCoverleft p {
    font-size: 16px;
    line-height: 30px;
}
.tableCoverleft p i {
    font-size: 26px;
    margin: 5px 0px;
    width: 16px;
    color: #fd8212;
    text-align: center;
    position: relative;
    top: 3px;
}
.tableCoverleft p span {
    display: inline-block;
    width: 23px;
}
p.priceCost {
    padding-left: 20px;
    color: #000000;
    font-size: 17px;
}
p.priceCost span {
    color: #adabab;
    width: auto;
    margin-right: 10px;
}
p.priceCost span:last-child {
    margin-left: 7px;
    margin-right: 0px;
}
p.priceCost span:last-child {
    margin-left: 7px;
}
p.priceCost img {
    position: relative;
    top: -1px;
}
.tablefoodbtn a {
    background: #fd8212;
    border: 1px solid #fd8212;
    color: #fff;
    font-weight: 500;
    font-size: 21px;
    border-radius: 5px;
    padding: 7px 22px;
    outline: none;
    box-shadow: none;
    cursor: pointer;
}
.tablefoodbtn a img {
    width: 40px;
    margin-right: 7px;
    position: relative;
    top: -2px;
}
.tablefoodbtn a:hover {
    background: #e87003;
    border-color: #e87003;
}
.react-datepicker__header--time{
    min-height: 80px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.react-datepicker__time-container{
    border-left:0px !important;
}
.react-datepicker {
    display: flex;
    width: 328px;
}
.manyPeople input.form-control.form-control {
    background-image: none;
    cursor: text;
}
.tableCoverleft p  span.star-reating.ml-2 {
    width: 100px;
}
.tableCoverleft p span.star-reating.ml-2 i {
    font-size: 17px;
}
.tableCoverleft p span.star-reating.ml-2 span {
    width: 60px;
    position: relative;
    left: 8px;
    top: 4px;
}
.outerPding .react-datepicker-wrapper {
    width: 100%;
}
.cover_right .tablefoodbtn a img {
    width: 28px;
    top: -3px;
}
.bookingArt {
    padding: 0px 8px 10px;
}
.bookingArt p {
    margin: 0px;
    font-size: 14px;
    color: #7d7d7d;
}
.bookingArt span {
    font-size: 14px;
    margin-top: -4px;
    display: block;
}
.bookingArt h5 {
    color: #333333;
}
.tatblereservationorderplaced .date-outer.color-black {
    color: #000;
}
.reservationCode span {
    padding: 7px 40px;
    background: transparent;
    border: 1px solid #dfdfdf;
    color: #ff8117;
    border-radius: 5px;
    margin: 10px 0px 0px;
    outline: none;
    cursor: text;
    display: inline-block;
}
.canclebooking button {
    background: red;
    color: #fff;
    padding: 8px 25px;
    border: 1px solid #f00;
    border-radius: 5px;
    font-weight: 600;
    outline: none;
    cursor: pointer;
}
.canclebooking button i {
    margin-right: 6px;
}
.canclebooking p {
    color: #7d7d7d;
    margin-top: 12px;
    margin-bottom: 5px;
}
.canclebooking p a {
    color: #ff8213;
    text-decoration: none;
}
.NeedOuter {
    border: 1px solid #dfdfdf;
    border-top: 0px;
    padding: 15px;
}
.NeedOuter p {
    color: #7d7d7d;
    font-size: 15px;
    margin: 0px;
}
.NeedOuter h5 {
    color: #ff8117;
}
.NeedOuter span a {
    color: #000;
    margin-bottom: 10px;
    display: inline-block;
    text-decoration: none;
    font-size: 15px;
}
.tatblereservationorderplaced span.Order-confirm-lbl {
    background: #fd8212 !important;
    border-color: #fd8212 !important;
    color: #fff;
}
.tatblereservationorderplaced span.Order-confirm-lbl.cnl {
    background: red !important;
    border-color: red !important;
}
p.pendingOuter {
    background: #fd8212;
    padding: 2px 11px;
    border-radius: 5px;
    display: inline-block;
    color: #fff;
    font-size: 19px;
}
p.confirmOuter {
    background: #008000;
    padding: 2px 11px;
    border-radius: 5px;
    display: inline-block;
    color: #fff;
    font-size: 19px;
}
p.cancelOuter {
    background: red;
    padding: 2px 11px;
    border-radius: 5px;
    display: inline-block;
    color: #fff;
    font-size: 19px;
}

.userinfobottom {
    display: inline-block;
    width: 100%;
    padding: 35px 15px 0px;
}
.userinfobottom .btn {
    font-size: 17px;
    padding: 10px 25px;
    border-radius: 55px;
    margin-right: 10px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    box-shadow: none !important;
}
.userinfobottom .btn.cancelbtn {
    background: #fd3c2d;
}
.userinfobottom .btn.cancelbtn:hover {
    background: #ea1f0e;
}
.userinfobottom .editbtn {
    background: #f88745;
}
.userinfobottom .editbtn:hover {
    background: #ef7127;
}
.userinfobottom h3 {
    margin-bottom: 17px;
}
.userinfobottom h4 {
    margin-top: 30px;
}
.userinfobottom ul {
    padding: 0px;
    margin: 20px 0 0;
    list-style: none;
}
.userinfobottom ul li span {
    display: inline-block;
    width: 150px;
}
.userinfobottom ul li {
    margin-bottom: 20px;
}
.upcoming_details .cover-left.tableCoverleft{
    width: 100% !important;
}
.upcoming_details .Checkout-item-content p span{
    display: inline-block;
    width:auto;
}
.upcomingproductcontent a {
    text-decoration: none;
}
.upcomingproductcontent a h4 {
    font-size: 22px;
}
.upcomingproductcontent a {
    text-decoration: none;
}
.upcomingproductcontent a h4 {
    font-size: 19px;
}
.tableCoverleft p i, .tableCoverleft p img {
    max-width: 18px;
    display: inline-block;
    width: 18px;
    object-fit: contain;
    margin-right: 8px;
}
.tableCoverleft p {
    margin-bottom: 5px;
}
.upcomingproductcontent p img {
    width: 21px;
    object-fit: contain;
    margin-right: 5px;
}
.reservationstatus {
    width: 60%;
    text-align: center;
    border: 1px solid #ccc;
    padding: 45px 30px;
    border-radius: 5px;
    margin: 30px auto;
}
.reservationstatus h3 {
    margin: 30px 0px 10px;
    font-size: 40px;
}
.reservationstatus p {
    color: #545454;
    font-size: 22px;
}
.reservationstatus p span {
    display: block;
}
.userdetails p {
    display: inline-block;
    width: 100%;
    text-align: left;
}

.userdetails p span {
    width: 50%;
    display: inline-block;
    text-align: right;
    padding-right: 6px;
}
span.reservationCode {
    border: 1px solid #fd8115;
    padding: 14px 25px;
    display: inline-block;
    border-radius: 5px;
    color: #545454;
    font-size: 22px;
    margin: 40px 0px 0px;
}
.mapMOdalBox ul li .fa-check {
    position: absolute;
    right: 0px;
    font-size: 40px;
    top: 50%;
    transform: translateY(-70%);
}
.addressmodalbox .formCenterDiv {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.addressmodalbox .formCenterDiv button {
    margin: 0px;
    background: #fff;
    border: 1px solid #fd8212 !important;
    padding: 9px 20px;
    border-radius: 5px;
    color: #fd8212;
    box-shadow: none !important;
}
.addressmodalbox .formCenterDiv button:hover,
.addressmodalbox .formCenterDiv button:focus {
    color: #fff;
    background: #fd8212 !important;
    outline: none;
    cursor: pointer;
}
#popular_photo .owl-nav button.owl-prev {
    position: absolute;
    left: -40px;
}

#popular_photo .owl-nav button.owl-next {
    position: relative;
    right: -80px;
}
#popular_photo .owl-nav {
    position: absolute;
    top: 40%;
    width: 100%;
}

#popular_photo .owl-nav button.owl-next {
    float: right;
}

#popular_photo .owl-nav button {
    height: 50px;
    line-height: 0px;
    outline: none;
    background: transparent;
}

#popular_photo .owl-nav button span {
    font-size: 76px;
    line-height: 0px;
    color: #fff;
}
.photoscss .owl-nav button span{
    color: #fd8212 !important;
}
.photoscss .owl-nav button.owl-prev {
    left: 10px !important;
}
.photoscss .owl-nav button.owl-next {
    right: 10px !important;
}
.photoscss .owl-stage img {max-height: 540px !important;min-height: 540px !important; margin-bottom: 15px;}
.Customization_modal .modal-header{
    padding: 0px;
}
.react-tabs__tab-panel--selected>.container {
    padding: 0px;
}
.ReactModal__Overlay {
    z-index: 1000;
}
#verifyOTPModel h4 {
    background: #ff8417;
    padding: 15px 10px 18px 15px;
    color: #fff;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
}
#verifyOTPModel {
    padding-top: 60px !important;
}
#verifyOTPModel button.btn.active {
    background: #ff8417;
    color: #fff;
}
/* Custom Radio Button */
.profileformopction .custom_radio{ display:inline-block; position:relative; cursor:pointer; line-height:22px; position:relative; padding-left:0px;width: 100%; margin:5px 0px;}
.profileformopction .custom_radio input{ width:100%; height:100%; opacity: 0; position: absolute; top: 0; left: 0; cursor: pointer; margin: 0px 0px; z-index: 2;}
.profileformopction .custom_radio .radio_indicator{ height:22px; width:22px; border-radius: 50%; background:#ffffff; border:1px solid #cccccc; position:absolute; top:0px; left:initial; right: 0px;}
.profileformopction .custom_radio input:checked + .radio_indicator:before{ width:12px; height:12px; border-radius: 50%; background-color: #ff8213; position:absolute; left:50%; top:50%; margin: -6px 0px 0px -6px; content:"";}
/* Custom Radio Button */
.profileformopction {
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding: 8px 0px;
    width: 320px;
}
.rightDark .right-hero-tilt::before{
    opacity: 1 !important;
    z-index: 100 !important;
}
.leftDark .left-hero-tilt::before{
    opacity: 1 !important;
    z-index: 100 !important;
}
.leftSidebarOuter ul li:hover img.currencyblack, .leftSidebarOuter ul li.react-tabs__tab--selected img.currencyblack {display: none;}
.leftSidebarOuter ul li img.currencyWhite {
    display: none;
}
.leftSidebarOuter ul li:hover img.currencyWhite,
.leftSidebarOuter ul li.react-tabs__tab--selected img.currencyWhite{
    display: inline-block !important;
}
.leftSidebarOuter ul li img {
    width: 19px;
}
.login-form .form-check input[type="checkbox"] {
    height: 22px;
    width: 22px;
}
button#manish {
    position: absolute;
    right: 0px;
    top: 0px;
}
.food-man a {
    display: inline-block;
    width: 100%;
    position: relative;
    top : 50px;
    z-index: 999;
}
header.newClassHeader .user-info-header {
    top: 1px;
}
