@media(min-width: 1700px) and (max-width: 1920px){
	html:not([data-device-type="mobile"]) .twin-hero .right-hero .right-hero-tilt .side-container .initial{
	    left: 31%;
	}
	html:not([data-device-type="mobile"]) .twin-hero .left-hero .left-hero-tilt .side-container .initial{
		right: 7%;
	}
}
@media(min-width: 1600px) and (max-width: 1700px){
	html:not([data-device-type="mobile"]) .twin-hero .right-hero .right-hero-tilt .side-container .initial{
	    left: 32%;
	}
	html:not([data-device-type="mobile"]) .twin-hero .left-hero .left-hero-tilt .side-container .initial{
		right: 6%;
	}
}
@media(min-width: 1400px) and (max-width: 1600px){
	html:not([data-device-type="mobile"]) .twin-hero .right-hero .right-hero-tilt .side-container .initial{
	    left: 31%;
	}
	html:not([data-device-type="mobile"]) .twin-hero .left-hero .left-hero-tilt .side-container .initial{
		right: 9%;
	}
	.login-right+.download-content .qr-code {
	    padding: 2px 0px;
	}
	.login-right+.download-content h2.effect-shine {
	    font-size: 37px;
	}
	.login-right+.download-content h2.effect-shine span {
	    display: block;
	}
	.qr-code img {
		width: 90px;
	}	
}
@media(min-width: 1200px) and (max-width: 1399.98px){
	.login-right+.download-content h2 {
	    font-size: 30px;
	}
	.qr-code{
		padding: 3px 0px;
	}
	.login-right+.download-content{
		width: 80%;
		top: 25px;
    	right: -130px;
	}
	.download-content h2{
		font-size: 40px;
	}
	html:not([data-device-type="mobile"]) .twin-hero .right-hero .right-hero-tilt .side-container .initial{
	    left: 30%;
	}
	html:not([data-device-type="mobile"]) .twin-hero .left-hero .left-hero-tilt .side-container .initial{
		right: 7%;
	}
	section.login::before{
	    width: 58%;
	    background-size: cover;
	}
	section.login::after{
	    width: 42%;
	    background-size: cover;
	}
	.login-right img.img-fluid{
		left: 20px;
	}
	.login-right span::after{
	    left: 50px;
	}
}
@media (max-width: 1199.98px){
	section.app-download {
	    min-height: 380px !important;
	}

	.qr-code img {
	    width: 80px;
	}
	.searchBoxFlip .search-outer {
	    width: 88%;
	    margin: 0 auto 15px;
	}
	.product-right-content p{
		min-height: 117px;
	}
	.product-right-content {
	    width: calc(100% - 150px);
	    float: left;
	}
	.veg_outer .order-right {
	    width: 100px;
	    float: left;
	}
	.formOuter h2, .ourInfo h2 {
	    font-size: 22px;
	    margin-bottom: 20px;
	}
	.login-right+.download-content h2 {
	    font-size: 30px;
	}
	html:not([data-device-type="mobile"]) .twin-hero .left-hero .left-hero-tilt .side-container .initial{
		right: 4%;
	}
	.Restaurant_overview .Checkout-item .item-img{
		top: -30px;
	}
	.Restaurants_list .Checkout-item-content h4 {
		font-size: 18px;
	}
	.cover-left{
		width:60%;
	}
	.cover_right{
		width:40%;
	}
	.Checkout-item-content h4{
		font-size: 26px;
	}
	.order-now button{
		font-size: 15px;
	}
	.date-time p{
		width: 50%;
	}
	#food-add .owl-nav button.owl-prev,
	.food-collection .owl-nav button.owl-prev, 
	.food-add-slider .owl-nav button.owl-prev{
		left: -20px;
	}
	#popular_product .owl-nav button.owl-prev{
		left: -10px;
	}
	#food-add .owl-nav button.owl-next,
	.food-collection .owl-nav button.owl-next, 
	.food-add-slider .owl-nav button.owl-next{
		right: -20px;
	}
	#popular_product .owl-nav button.owl-next{
		right: -10px;
	}
	.mobile-img img {
        width: 241%;
    	left: -60px;
    	top: 30px;
	}
	.mobile-img{
		width: 270px;
	}
	.food-man {
        width: 230px;
	    top: 0;
	    background-size: 100%;
	    left: 50px;
	}
	.search-outer{
		width: 85%;
	}
	.download-content h2{
		font-size: 38px;
	}
	section.app-download {
		background-position: bottom;
		background-size: 150%;
	}
	.download-content {
	    top: 80px;
	    left: 70px;
	}
	img.footer-bottom {
	    width: 180px;
	    z-index: 0;
	}
	section.food-collection{
		background-size: 150px;
		padding: 20px 0px 20px;
	}
	section.login::before{
	    width: 58%;
	    background-size: cover;
	}
	section.login::after{
	    width: 42%;
	    background-size: cover;
	}
	.login-right img.img-fluid{
		left: 20px;
	}
	.login-right span::after{
	    left: 50px;
	}
	section.trending-categories{
		padding: 20px 20px;
	}
	.locationHeaderuser a{
	    left: -9px;
	}
	


}
@media(max-width: 991.98px){
	section.app-download {
	    min-height: 330px !important;
	}
	.food-man {
	    width: 170px;
	    top: 14px;
	    background-size: 100%;
	    left: 110px;
	}
	.main-menu{
		margin: 0px;
	}
	.user-info-header{
		height: initial;
	}
	a.loginback{
		position: absolute;
		top: -1px;
	}
	.user-info-header{
	    top: -0px;
	    margin: 2px 0px;
	}
	span.location .dropdown{
		margin: 0px;
	}
	.logo-outer {
	    margin-top: 70px;
	}
	.reservationstatus p, .reservationstatus p{
		font-size:16px;
	}
	.reservationstatus h3 {
		font-size: 24px;
	}
	span.reservationCode{
		padding: 7px 15px;
		font-size: 13px;
	}
	.tablefoodbtn a{
		font-size: 16px;
		padding: 7px 10px;
	}
	.locationHeaderuser a {
	    left: 40px;
	}
	.profileFormOuter{
		margin-top: 10px;
	}
	h4.mainHeading{
		font-size: 27px;
	}

	.hereIScontactOuter {
	    width: 100%;
	}
	.login-right+.download-content{
		min-height: 250px;
		padding-left: 50px;
	}
	.siderbarmenu-button {
		display: block !important;
	}
	.custon-sidebar{	
		width: 0px;	
		border: 0px;
		opacity: 0;	
		display: none;
	}	
	.Order_Food section.page {	
	    margin-left: 0px;	
	    height: 100%;	
	    padding: 15px 0px;	
	}
	.login-header{
		background-color: #ff8213;
		padding-bottom: 0px;
	}
	.main-menu ul li {
		text-align: left;
	}
	.login-right{
		padding-top: 100px;
	}
	
	section.best-food{
		padding: 20px 0px;
	}
	.repet-flip .product_img a{
		padding: 13px 25px;
	}
	.left-three{
		position: relative;
		top:-22px;	
	}
	.add_img img{
		height: 145px;
	}
	#food-add .owl-item img {
		height: 160px;
	}
	.thumbnail img{
		height: 170px !important;
	}
	.order-details .order-delivery{
		width: 50%
	}
	.copun_code, .food-veg{
		width: 25%;
	}
	.table_res{
		width: 100%;
	}
	.category-left{
		width: 100%;
	}
	.secrch-right{
		width: 90%;
	}
	.Recommended_product_outer {
	    margin-bottom: 10px;
	    border-bottom: 1px solid #ffb06a;
	}

	.veg_outer .order-right {
	    width: 100px;
	    float: left;
	}
	html:not([data-device-type="mobile"]) .twin-hero .right-hero .right-hero-tilt .side-container .initial{
	    left: 29%;
    	top: 44px;
	}
	html:not([data-device-type="mobile"]) .twin-hero .left-hero .left-hero-tilt .side-container .initial{
	    right: 10%;
    	top: 140px;
	}
	.main-menu::before{
		left: -65px;
	}
	.search-outer{
		width: 80%;
	}
	.mobile-img {
	    width: 100px;
	}
	.download-content {
	    left: 110px;
	    top: 115px;
	}
	.download-content h2 {
	    font-size: 28px;
	}
	.qr-code img:first-child {
	    margin-right: 25px;
	}
	.qr-code img {
	    width: 52px;
	}
	.aap-store-google{
		padding: 20px 0px;
	}
	.aap-store-google img {
		width: 120px !important;
	}
	.mobile-img img {
	    width: 541%;
	    left: -100px;
	    top: 16px;
	}

	ul.social-list li a img {
	    width: 30px;
	}
	.twin-hero{
		height: 450px;
	}
	.main-menu ul li a{
		padding: 3px 0px !important;
		text-align: left;
	}
	.product-cart{
		top: 20px;
		right: 25px;
	}
	.main-menu .navbar-nav.mr-auto{
		padding-top: 15px;
	}
	.main-menu ul li a::before, .main-menu::before{
		display: none;
	}
	.main-menu{
		border-radius: 5px 0px 0px 5px;
		width: 150px;
	}
	.main-menu .bg-light{
		width: 100%;
	}
	section.login::before {
	    width: 100%;
	    left: 0px;
		background-size: cover;
		background-position: right;
	    bottom: 55%;
	    top: 0px;
	}
	section.login::after {
	    width: 100%;
	    right: 0px;
	    background-size: cover;
	    background-repeat: no-repeat;
	    bottom: 0px;
	    top: 45%;
	}
	h2.horizntal {
	    transform: rotate(0deg);
	    top: initial;
	    right: 0;
	    font-size: 34px;
	    bottom: 0px;
	    width: 100%;
	    text-align: center;
	    margin: 0px;
	}
	.login-right img.img-fluid{
		left: 0px;
	}
	.login-right span::after{
		left: 20px;
	}
	.login-outer{
		width: 100%;
		padding: 30px 0px 40px;
	}
	.login-outer h3{
		font-size: 18px;
	}
	.login-outer h3 span {
	    font-size: 24px;
	}
	/* .login-header{
		background: transparent;
	} */
	nav.side_bar.main {
		width: 250px;
		position: fixed;
		padding: 0px;
		z-index: 10000;
		background: #f9f9f9;
		border-right: 1px solid #ccc;max-height: 100vh;
		overflow-y: auto;
	}
	.custon-sidebar.overlay_outer {
		display: block;
		opacity: 1;
	}
	nav.side_bar.main + .overlay {
		position: fixed;
		top: 0px;
		left: 0px;
		right: 0px;
		background: rgba(251, 251, 251, 0.7607843137254902);
		bottom: 0px;
		z-index: 1000;
	}
	.main-menu .bg-light{
		padding: 5px 53px 5px 0px;
	}
	.searchBoxFlip .search-outer{
	    width: 78%;
    	display: inline-flex;
	}

}

@media(max-width: 800px){
	section.app-download {
	    min-height: 314px !important;
	}
	.mobile-img img {
	    width: 511%;
	    left: -70px;
	    top: 16px;
	}
}
@media(max-width: 767.98px){
	.download-content{
		text-align: center;
	}
	.app-download .col-md-12{
		display: initial;
	}
	section.login{
		padding-bottom: 0px;
	}
	section.login::before{
		bottom: 45%;
	}
	section.login.signupouter::after{
		display: none;
	}
	section.login.signupouter .col-12.col-sm-12.col-md-12.col-lg-5.col-xl-5{
		background-image: url(/static/media/login-after.6ed16ec9.jpg);
	}
	section.login.signupouter .login-right{
		padding-top: 20px;
	}
	.login-right{
		padding-top: 70px;
	}
	.container{
		max-width: 100%;
	}
	
	.user-info-header{
		top: -1px;
	}
	.react-tabs__tab-panel--selected .mt-2.mb-2.p-4 {
		padding: 15px !important;
	}
	.reservationstatus{
		width: 100%;
		padding: 25px 20px;
	}
	.userdetails p span{
		width: 120px;
	}
	.reservationstatus p, .reservationstatus p {
		font-size: 13px;
	}
	#food-add .owl-nav{
		top:35% !important;
	}
	section.trending-categoriestbl{
		padding: 20px 20px 70px !important;
	}
	.tableCoverleft p span.star-reating.ml-2 i{
		position: relative;
		top: 5px;
		left: 9px;
	}
	.tableCoverleft p, p.priceCost{
		padding-left: 0px;
		font-size: 12px;
	}
	.formCenterDiv{
		width: 100%;
	}
	.currentuserimg, .upcomingProductImg, .upcomingProductImg img {
		width: 50px;
		height: 50px;
	}
	.upcomingproductcontent {
		width: calc(100% - 50px);
		padding-left: 14px;
	}
	h4.mainHeading{
		font-size: 21px;
	}
	.Restaurants_list.p-2 .Checkout-item-content{
		width: calc(100% - 50px);
		padding-right: 45px;
	}
	.Restaurants_list .Checkout-item-content h4 {
		font-size: 15px;
	}
	.Delivery_location, .Food_basket{
		margin-top: 15px;
	}
	.react-tabs__tab{
		padding: 6px 6px !important;
		font-size: 13px;
		text-align: center;
	}
	.veg_pizza h5,
	.Material .card-header button.btn{
		font-size:14px;
	}
	.product-right-content h4,
	.product-right-content span.order-price,
	.product-right-content p, .product-right-content p span,
	.Checkout-item-content span.total-review {
		font-size: 13px;
	}
	.Customization_product_content h4{
		font-size: 21px;
	}
	.minodr span, .deliverych span{
		font-size:15px;
	}
	.breadcrumb-outer nav{
		width: 100%;
	}
	.breadcrumb-outer button{
		float: left;
	    margin-top: -8px;
	}
	span.mobilelocation {
	    display: block;
	    color: #ff8213;
	    font-size: 25px;
	    position: absolute;
	    top: 17px;
	    cursor: pointer;
	}
	.locationHeaderuser a{
		display: none;
	}
	.searchBoxFlip .search-outer{
		width: 100%;
	}
	.itempriceouter p, .currentOrderUserinfo h4.summeryprice{
		font-size: 16px;
	}
	.addressmodalbox .formCenterDiv button{
		font-size: 12px;
		padding: 9px 10px;
	}
	.login-right+.download-content h2 {
	    font-size: 28px;
	}
	.login-right+.download-content h2 span {
	    display: block;
	}
	.cover_img .userProfile{
		width: 100px;
		height: 100px;
	}
	.cover_img .userProfile>img{
		width: 90px;
		height: 90px;
	}
	.userInfoProfile {
	    padding-left: 125px;
	    margin-top: -43px;
	}
	.userInfoProfile h2.userProfileName{
		margin-bottom: 50px;
	}
	.massageDiscount .open_now{
		width: 130px;
		padding-right: 0px;
	}
	.product-right-content p{
		min-height: initial;
	}
	.massageDiscount h5 {
	    font-size: 15px;
	}
	.ourInfo{
		margin-top: 30px;
	}
	.terms h1{
		font-size: 24px;
	}
	.terms h5 {
		font-size: 18px;
	}
	.user-product-outer {
		width: 100%;
		float: left;
		border-bottom: 1px solid #dee2e6;
	}
	.food-delivery-outer{
		display: flex;
		align-items: center;
		justify-content: space-between !important;
		white-space: nowrap;
		overflow: auto;
		padding-top: 120px;
	}
	.left-outer img.idea-img {
		left: 60px;
		top: -90px;
	}
	.repet-flip h5{
		width: 100%;
		float: left;
	}
	.left-one {
		float: left;
		width: 190px !important;
		min-width: 240px;
	}	
	#food-add .owl-item .item,
	#food-category .item,
	.food-add-slider .item {
		width: 100%;
		margin: 0 auto;
	}
	.cover-left, .cover_right{
		width:100%;
	}
	.social_item {
		width: 135px;
		float: left;
		text-align: left;
	}
	.Delivery_table {
		width: calc(100% - 135px);
		display: flex;
		float: left;
	}
	.Delivery_table .table_res,
	.table_res_Delivery{
		padding: 5px 0px;
	}
	.social_item a{
		margin: 0px 0px 0px 0px;
	}
	.thumbnail img {
		height: 130px !important;
	}	
	ul.materials_list li a{
	    padding: 5px 13px;
	}
	.Customization_product_content{
		padding-top: 15px;
	}
	.Beverage select.custom-select.form-control{
		width: 45%;
	}
	.share_add button.add_pd, .share_add button.add_pd_dis, .share_add button.share_pd{
	    font-size: 16px;
    	padding: 10px 10px;
    	margin: 0px 6px;
	}
	.Checkout-item-content h4{
		font-size: 22px;
	}
	.Checkout-item .item-img{
		width: 60px;
		padding: 2px;
	}
	.Checkout-item-content{
		width: calc(100% - 70px);
	}
	.order-form-outer h4{
	    font-size: 19px;
	}
	.Delivery-left{
		width: 100%;
		border-right: 0px;
	}
	.green-right{
		width: 100%;
		padding-left: 0px;
		padding-top: 0px;
	}
	span.payment-item a img {
	    width: 22px;
	    top: 2px;
	}
	.payment-method{
		margin-bottom: 20px;
	}
	.green-right textarea.form-control{
		margin-bottom: 15px;
	}
	.future-order span.custom_radio {
	    display: block;
	}
	.apply-code{
		padding-bottom: 10px;
	}
	.user-info-header button span{
		left: 0px;
	}
	.inner-header .right-header{
		padding-top: 0px;
	}
	section.Order-Placed {
	    padding: 10px 0px;
	}
	header.inner-header+section {
	    margin-top: 70px;
	}
	.order-heading h2, .live-chat h4{
		font-size: 22px;
	}
	.Order-Summary h3{
		font-size: 19px;
	}
	.breadcrumb-outer .breadcrumb-item+.breadcrumb-item {
	    padding-left: 5px;
	}
	.breadcrumb-outer .breadcrumb-item+.breadcrumb-item::before{
		padding-right: 5px;
	}
	.breadcrumb-outer nav ol.breadcrumb li, .breadcrumb-outer nav ol.breadcrumb li a{
		font-size: 13px;
	}
	html:not([data-device-type="mobile"]) .twin-hero .right-hero .right-hero-tilt .side-container .initial{
		left: 27%;
		top:44px;
	}
	html:not([data-device-type="mobile"]) .twin-hero .left-hero .left-hero-tilt .side-container .initial{
		right: 10%;
	    top: 130px;
	}
	.col-md-6.best-food-left{
		padding-right: 15px;
		margin-bottom: 11px;
	}
	.mobile-img img {
	    width: 551%;
	    left: -110px;
	    top: 16px;
	}
	.aap-store-google img {
	    width: 100px !important;
	}
	.aap-store-google img:first-child {
	    margin-right: 8px;
	}
	.download-content h2{
		font-size: 22px;
	}
	.footer-links {
	    margin-bottom: 30px;
	}
	.headings-outer h2, .work-heading h2{
		font-size: 22px;
	}
	.work-heading h2 span{
		font-size: 24px;
	}
	.product_outer {
	    padding: 5px 0px;
	}
	.trending-categories .owl-item .item {
		width: 90%;
		margin: 0 auto;
	}
	section.search-box{
		padding: 20px 0px;
	}
	.search-right::after, .search-right span::before{
		display: none;
	}
	.search-outer{
		width: calc(100% - 85px)
	}
	.search-outer .delivery-location{
		width: 200px;
	    padding: 20px 9px;
	}
	.search-right{
		width: calc(100% - 200px);
	}
	header .container {
	    width: 100% !important;
	    max-width: 95%;
	}
	.user-info-header button{
		padding: 0px 5px;
	}
	.user-info-header{
		display: flex;
    	justify-content: flex-end;
	}
	section.Works-food-delivery{
		padding: 0px 0px 20px;
	}
	.food-add-slider {
	    padding: 20px 0px 0px;
	}
	section.twine_slider {
	    margin-top: 93px;
	}
	header{
		position: absolute;
		background: #fff;
	}
	#food-add .owl-item img, .add_img img {
		height: auto;
	}
	.pickupDelivery {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		position: relative;
		top: 7px;
		margin: 0px;
		width: 100%;
	}
	.tablefoodbtn a {
		float: left;
	}	
	.pickupDelivery span.ml-2 {
		margin-left: 0px !important;
	}
	.locationmodule .modal-lg{
		max-width: 100%;
   }
   .Customization_modal {
		padding-right: 0px;
	}
	.logo img {
		width: 85px;
		position: relative;
		top: 8px;
	}
	span.locationMapicon{
		top:46px;
	}
	header{
		padding-bottom: 0px;
	}
	.right-header{
		padding: 4px 0px 0px 0px;
	}
	.food-man {
	    width: 170px;
	    top: 0;
	    background-size: contain;
	    left: 90px;
	    height: 180px;
	}

}
@media(max-width: 640px){

	
	header{
		padding-bottom: 0px;
	}
	span.mobilelocation {
		top: 16px;
		left:36px;
	}
	header.inner-header + .main {
		margin-top: 82px;
	}
	.secrch-right {
	    width: 78%;
	}
	.order-details .order-delivery{
		width: 100%;
	}
	.copun_code, .food-veg{
		width: 50%;
	}
	section.twine_slider {
	    margin-top: 55px;
	}
	html:not([data-device-type="mobile"]) .twin-hero .left-hero{
		width: 100%;
		transform: skewX(0deg);
    	-moz-transform: skewX(0deg);
    	-webkit-transform: skewX(0deg);
    	left: 0px;
    	height: 50%;
	}
	html:not([data-device-type="mobile"]) .twin-hero .left-hero .left-hero-tilt{
		transform: skewX(0deg);
    	-moz-transform: skewX(0deg);
    	-webkit-transform: skewX(0deg);
    	margin:0px;
    	width: 100%;
	}
	html:not([data-device-type="mobile"]) .twin-hero .right-hero{
		width: 100%;
		transform: skewX(0deg);
    	-moz-transform: skewX(0deg);
    	-webkit-transform: skewX(0deg);
    	right: 0px;
    	position: initial;
    	height: 50%;
	}
	html:not([data-device-type="mobile"]) .twin-hero .right-hero .right-hero-tilt{
		transform: skewX(0deg);
    	-moz-transform: skewX(0deg);
    	-webkit-transform: skewX(0deg);
    	margin:0px;
    	width: 100%;
	}
	.logo a.nav-link{
		padding-left: 0px;
		padding-bottom: 0px;
	}
	.logo a.nav-link img {
		width: 80px;
		position: relative;
		top: 8px;
	}
	.right-header{
		padding: 6px 0px 0px 0px;

	}
	.user-info-header{
		margin-bottom: 4px;
	}
	span.locationMapicon{
		top:40px;
	}
	
	.user-info-header button{
		padding: 0px;
		font-size: 13px;
	}
	.main-menu{
		margin: 0px;
	    width: 210px;	
	    position: relative;
	}
	.user-info-header button span img {
	    width: 12px;
	}
	.user-info-header button span.down-icon{
	    left: 2px;
        font-size: 10px;
	}
	.main-menu .bg-light{
		padding: 5px 49px 5px 0px;
	}
	button.navbar-toggler{
	    font-size: 17px;
    	padding: 4px 3px 5px;
	}
	button.navbar-toggler span.navbar-toggler-icon {
	    height: initial;
	}
	.product-cart{
	    top: 10px;
    	right: 8px;
	}
	.mobile-img{
		width: 100%;

	}
	.mobile-img img{
		width: 100%;
		left: 0px;
		top: 0px;
	}
	.download-content {
	    left: 0px;
	    top: 30px;
	    width: 100%;
	}
	section.app-download{
		background-size: cover;
	}
	.food-man {
        width: 250px;
		height: 280px;
    	background-size: contain;
    	margin-top: 50px;
	}
	section.food-collection{
		padding-top: 20px;
	}
	section.best-food {
	    background-size: 130px;
	    padding: 10px 0px 0px;
	}
	html:not([data-device-type="mobile"]) .twin-hero .left-hero .left-hero-tilt .side-container .initial,
	html:not([data-device-type="mobile"]) .twin-hero .right-hero .right-hero-tilt .side-container .initial {
	    right: 0;
	    top: 0px;
	    padding: 0px;
	    display: inline-flex;
	    align-items: center;
	    justify-content: center;
	    height: 100%;
	    width: 100%;
	    left: 0px;
	}
	span.food-delivery-btn button,
	span.food-delivery-right-btn button{
		transform: skew(0deg);
	    outline: 6px solid #ffffffb0;
	    border: 0px;
	    padding: 14px 25px;
	    color: #fff;
	    font-size: 18px;
	}
	span.food-delivery-btn button span,
	span.food-delivery-right-btn button span{
		transform: skew(0deg);
	}
	span.food-delivery-btn button img,
	span.food-delivery-right-btn button img{
	    width: 28px;
	}
	html:not([data-device-type="mobile"]) .twin-hero .left-hero .left-hero-tilt .side-container,
	html:not([data-device-type="mobile"]) .twin-hero .hero-container .side-container {
	    float: right;
	    width: 100%;
	}
	.twin-hero {
	    height: 1000px;
	}

}
@media(max-width: 575.98px){

	.react-datepicker_day, .react-datepickerday-name, .react-datepicker_time-name{
	    width: 1.1rem !important;
    	line-height: 1.4rem !important;
	}
	.pickupDelivery {
	    justify-content: flex-start;
	}
	.itempriceouter p{
		font-size: 13px;
	}
	.product-right-content{
		width: calc(100% - 50px);
	}
	.veg_outer .order-right{
		width: 100%;
		padding-top: 10px;
	}
	.cover_img .userProfile {
	    width: 70px;
	    height: 70px;
	    bottom: -10px;
	}
	.userInfoProfile {
	    padding-left: 97px;
	    margin-top: -35px;
	}
	.userInfoProfile h2.userProfileName {
	    margin-bottom: 30px;
	    font-size: 18px;
	}
	.cover_img .userProfile>img {
	    width: 60px;
	    height: 60px;
	}
	.cover_img .userProfile span.uploadImg{
		bottom: 0px;
    	right: -7px;
	}
	.hereIScontactOuter {
	    padding: 20px;
	}
	.trending-categories .owl-item .item {
		width: 90%;
		margin: 0 auto;
	}
	#food-add .owl-item .item,
	#food-category .item,
	.food-add-slider .item {
		width: 90%;
		margin: 0 auto;
	}
	.food-img img{
		height: initial !important;
		max-height: 150px;
	}
	
	.user-info-header button span img {
		width: 14px;
		position: relative;
		left: -5px;
	}
	.Delivery_table .table_res, .table_res_Delivery {
		width: 100%;
		margin: 0px;
		text-align: left;
	}
	.Delivery_table .table_res_Delivery button,
	.Delivery_table .table_res button{
		float: left;
	}
	.social_item, .Delivery_table {
		width: 100%;
		display: block;
	}
	.social-outer {
	    display: flex;
	}
	.social-outer span{
		min-width: auto !important;
		margin: 5px !important;
		display: table;
		width: auto !important;
	}
	.social-outer span img{
		margin-right: 10px;
	}
	.Restaurants_list.p-2 .Checkout-item-content .social_item {
		float: right;
		position: absolute;
		top: -4px;
		right: 0;
		width: initial;
	}
}
@media(max-width: 479.98px){
	.searchBoxFlip .search-outer{
		display: initial;
	}
	.menuBar{
		width: 100%;
		text-align: center;
		margin-bottom: 5px;
	}
	.react-fancybox .box .image-box img.close-btn+img {
		max-width: 290px;
		max-height: 282px;
	}
	section.Order-Placed.restaurant_menu_detail .item {
		width: calc(100% / 2);
	}
	.thumbnail img {
		height: 160px !important;
	}
	.search-outer{
		display: inline-block;
		width: 100%;
	}
	.search-outer .delivery-location{
		width: 100%;
		text-align: center;
	}
	.search-right {
	    width: 100%;
	    border-top: 0px;
	    border-left: 5px solid #ff8214;
	}
}

@media(min-width: 1200px) and (max-width: 1530px){
	section.app-download{
		min-height: 430px;
	}
	.mobile-img img {
	    width: 281%;
	    left: -100px;
	    top: 20px;
	}
	.mobile-img {
	    width: 270px;
	}
	.food-man {
	    width: 230px;
	    top: 0;
	    background-size: 100%;
	    left: 50px;
	}

	.download-content {
	    top: 138px;
	    left: 70px;
	}
	.download-content h2 {
	    font-size: 38px;
	}
}

@media(max-width:1299.98px){
	.logo a.nav-link {
	    padding: 5px 15px 20px 120px;
	}
	section.app-download{
		min-height: 430px;
	}
}
@media(max-width:1199.98px){
	.logo a.nav-link {
	    padding: 5px 15px 20px 70px;
	}
}
@media(max-width:1100px){
	.logo a.nav-link {
	    padding: 5px 15px 20px 110px;
	}
}
@media(max-width:991.98px){
	.logo a.nav-link {
	    padding: 5px 15px 20px 30px;
	}
}
@media(max-width: 767.98px){
	header .logo a.nav-link {
	    padding: 5px 15px 20px 15px;
	}
	.userinfobottom .btn{
		font-size: 14px;
		margin-bottom: 15px;
	}
}
